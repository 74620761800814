import { useTranslation } from 'react-i18next';

import { getFormattedShortDateTime } from '../../../shared/utils/dateHelpers';
import { getRecommendationsUpdateTime } from '../../../shared/api/analytics';
import { useFetchData } from '../../../shared/hooks/useFetchData';

import styles from './RecommendationsCount.module.scss';

interface Props {
  count: number;
}

const RecommendationsCount = ({ count }: Props) => {
  const { t, i18n } = useTranslation();

  const [updateTime] = useFetchData<{ updated_at: string } | undefined>(
    getRecommendationsUpdateTime,
    undefined,
  );

  return (
    <>
      <span className={styles.count}>{count}</span>
      {t('RecommendationsTable.newRecommendations', { count })}
      {updateTime?.updated_at && (
        <>
          , {t('Table.lastUpdate')}{' '}
          {getFormattedShortDateTime(
            new Date(updateTime?.updated_at),
            i18n.language,
          )}
        </>
      )}
    </>
  );
};

export default RecommendationsCount;
