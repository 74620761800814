import { useState } from 'react';
import filterNames from '../../shared/constants/filterNames';
import uniq from 'lodash/uniq';
import { Recommendation } from './types';
import { useTranslation } from 'react-i18next';
import {FilterOption, Filters} from "../TableFilters/types";

const initialFilters: Filters = {
  area: { [filterNames.all]: true },
  status: { [filterNames.all]: true },
};

/**
 * filters - object with currently active filters
 * filterOptions - all possible values that could be set to filter object
 */

const useRecommendationFilters = (recommendations: Array<Recommendation>) => {
  const { t } = useTranslation();

  const [filters, updateFilters] = useState(initialFilters);

  const filterOptions: Array<FilterOption> = [
    {
      name: t('RecommendationsTable.areaFilter'),
      key: 'area',
      items: [
        { name: t('Table.All'), key: filterNames.all },
        ...uniq(recommendations.map(el => el.area)).map(filterName => ({
          name: filterName,
          key: filterName,
        })),
      ],
    },
    {
      name: t('RecommendationsTable.statusFilter'),
      key: 'status',
      items: [
        { name: t('Table.All'), key: filterNames.all },
        ...uniq(recommendations.map(el => el.status)).map(filterName => ({
          key: filterName,
          name: t(`Statuses.${filterName}`),
        })),
      ],
    },
  ];

  return { filters, filterOptions, updateFilters };
};

export default useRecommendationFilters;
