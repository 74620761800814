import React from 'react';
import styles from './Pagination.module.scss';
import classNames from 'classnames';
import range from 'lodash/range';
import { ReactComponent as DoubleShortArrow } from '../../../shared/assets/images/doubleShortArrow.svg';
import { ReactComponent as ShortArrow } from '../../../shared/assets/images/shortArrow.svg';

interface Props {
  currentPage: number;
  pageSize: number;
  numberOfEntities: number;
  goToPage: any;
}

const getPaginatingLabels = (pageCount: number, currentPage: number) => {
  const MIN_PAGE = 0,
    MAX_PAGE = pageCount - 1,
    labelsMargin = 2;

  let start = currentPage - labelsMargin;
  let end = currentPage + labelsMargin;

  if (start < MIN_PAGE) {
    let newEnd = end + (MIN_PAGE - start);
    end = Math.min(newEnd, MAX_PAGE);
    start = Math.max(MIN_PAGE, start);
  }

  if (end > MAX_PAGE) {
    let newStart = start - (end - MAX_PAGE);
    start = Math.max(MIN_PAGE, newStart);
    end = Math.min(MAX_PAGE, end);
  }

  return range(start, end + 1, 1);
};

const Pagination = ({
  currentPage,
  pageSize,
  numberOfEntities,
  goToPage,
}: Props) => {
  const numberOfPages = Math.ceil(numberOfEntities / pageSize);
  const pageLabels = getPaginatingLabels(numberOfPages, currentPage);

  const changePage = (nextPage: number) =>
    nextPage >= 0 && nextPage < numberOfPages && goToPage(nextPage);

  return (
    <div className={styles.wrapper}>
      <div className={styles.summary}>
        {currentPage * pageSize + 1} -{' '}
        {Math.min((currentPage + 1) * pageSize, numberOfEntities)} /{' '}
        {numberOfEntities} entities
      </div>
      <div className={styles.controls}>
        <button
          className={classNames(styles.button, styles.arrow)}
          onClick={() => changePage(0)}
        >
          <DoubleShortArrow className={styles.rotate270} />
        </button>
        <button
          className={classNames(styles.button, styles.arrow)}
          onClick={() => changePage(currentPage - 1)}
        >
          <ShortArrow className={styles.rotate270} />
        </button>
        {pageLabels.map(pageNumber => (
          <button
            key={'pageNumber' + pageNumber}
            className={classNames(
              styles.button,
              styles.pageLabel,
              pageNumber === currentPage && styles.selected,
            )}
            onClick={() => changePage(pageNumber)}
          >
            {pageNumber + 1}
          </button>
        ))}
        <button
          className={classNames(styles.button, styles.arrow)}
          onClick={() => changePage(currentPage + 1)}
        >
          <ShortArrow className={styles.rotate90} />
        </button>
        <button
          className={classNames(styles.button, styles.arrow)}
          onClick={() => changePage(numberOfPages - 1)}
        >
          <DoubleShortArrow className={styles.rotate90} />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
