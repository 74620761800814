import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Tooltip from '../Tooltip/Tooltip';

import styles from './PopupMenu.module.scss';

export interface PopupMenuProps {
  button: React.ReactNode;
  children?: React.ReactNode;
  tooltip?: string;
  tooltipPosition?: 'top' | 'bottom';
  xAlign?: 'left' | 'right';
  yAlign?: 'top' | 'bottom';
  opened?: boolean;
  onToggle?: (isOpen: boolean) => void;
  testKey?: string;
  childrenClassName?: string;
  buttonClassName?: string;
  highlightClassName?: string;
}

const PopupMenu = ({
  button,
  tooltip,
  tooltipPosition = 'bottom',
  xAlign = 'right',
  yAlign = 'bottom',
  children,
  onToggle,
  testKey = '',
  opened = false,
  childrenClassName,
}: PopupMenuProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(opened);
  const [isHighlighted, setHighlighted] = useState(false);

  useEffect(() => setOpen(opened), [opened]);

  useEffect(() => {
    if (onToggle) onToggle(isOpen);
  }, [isOpen, onToggle]);

  useEffect(() => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const hideMenu = (e: MouseEvent) => {
      if (!ref.current || !ref.current.contains(e.target as Element)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', hideMenu);
    return () => document.removeEventListener('click', hideMenu);
  }, [setOpen]);

  return (
    <div
      ref={ref}
      className={styles.container}
      onMouseEnter={() => setHighlighted(true)}
      onMouseLeave={() => setHighlighted(false)}
    >
      <Tooltip
        disabled={!tooltip}
        text={tooltip || ''}
        position={tooltipPosition}
        className={styles.tooltip}
      >
        <div
          data-testid={testKey + '-button'}
          className={classNames(
            styles.button,
            (isOpen || isHighlighted) && styles.highlightedButton,
          )}
          onClick={() => setOpen(!isOpen)}
        >
          <div
            className={classNames(
              styles.icon,
              (isOpen || isHighlighted) && styles.highlightedIcon,
            )}
          >
            {button}
          </div>
        </div>
      </Tooltip>
      {isOpen && (
        <div
          data-testid={testKey + '-content'}
          className={classNames(
            styles.childrenWrapper,
            xAlign === 'left' && styles.childrenWrapperAlignLeft,
            yAlign === 'top' && styles.childrenWrapperAlignTop,
            childrenClassName,
          )}
          ref={contentRef}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default PopupMenu;
