import React from 'react';
import styles from './DatePeriodPicker.module.scss';
import { DatePeriod } from '../../shared/utils/getDatePeriodRange';
import RadioButton from '../RadioButton/RadioButton';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface Props {
  datePeriod: DatePeriod | null;
  onDatePeriodChange: (date: DatePeriod) => void;
  className?: string;
  periods?: DatePeriod[];
}

const DatePeriodPicker = ({
  datePeriod,
  onDatePeriodChange,
  className,
  periods = [DatePeriod['30days'], DatePeriod['7days'], DatePeriod['24hours']],
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.periods, className && className)}>
      {periods.map(period => (
        <RadioButton
          key={period}
          checked={datePeriod === period}
          onChange={() => onDatePeriodChange(period)}
          label={t(`DatePeriodPicker.${period}`)}
        />
      ))}
    </div>
  );
};

export default DatePeriodPicker;
