import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styles from './Database.module.scss';

interface Props {
  tableName: string;
  count: number;
}

const DatabaseSection = ({ tableName, count }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Link
      to={`${pathname}/${tableName}`}
      className={classNames(styles.halfSection, styles.link)}
    >
      <div>
        <div className={styles.tableName}>
          {tableName.charAt(0).toUpperCase() + tableName.slice(1)}
        </div>
        <div className={styles.tableDesc}>
          {t(`Database.table_desc.${tableName}`)}
        </div>
      </div>
      <div className={styles.number}>{count} records</div>
    </Link>
  );
};

export default DatabaseSection;
