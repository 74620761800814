import React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../routes';

import NavBarLink from './NavBarLink/NavBarLink';
import styles from './NavBar.module.scss';
import { hideElements } from '../../shared/common';
import appConfig from '../../application.json';

import { ReactComponent as RecsIcon } from '../../shared/assets/images/recsIcon.svg';
import { ReactComponent as ImpactIcon } from '../../shared/assets/images/impactIcon.svg';
import { ReactComponent as PerformanceIcon } from '../../shared/assets/images/performanceIcon.svg';
import { ReactComponent as ReportingIcon } from '../../shared/assets/images/reportingIcon.svg';
import { ReactComponent as DatabaseIcon } from '../../shared/assets/images/databaseIcon.svg';
import { ReactComponent as ApiIcon } from '../../shared/assets/images/apiIcon.svg';

const NavBar = () => {
  const { t } = useTranslation();

  return (
    <aside className={styles.container}>
      <nav className={styles.navBar}>
        <ul>
          {appConfig?.pages?.analytics && (
            <NavBarLink
              path={ROUTES.initial}
              icon={<RecsIcon />}
              label={t('Routes.recommendations')}
            />
          )}

          {appConfig?.pages?.impact_tracking && (
            <NavBarLink
              path={ROUTES.impactTracking}
              icon={<ImpactIcon />}
              label={t('Routes.impactTracking')}
            />
          )}

          {appConfig?.pages?.performance && (
            <NavBarLink
              path={ROUTES.performance}
              icon={<PerformanceIcon />}
              label={t('Routes.performance')}
            />
          )}

          {appConfig?.pages?.reporting && (
            <NavBarLink
              path={ROUTES.reporting}
              icon={<ReportingIcon />}
              label={t('Routes.reporting')}
            />
          )}

          {appConfig?.pages?.database && (
            <NavBarLink
              path={ROUTES.database}
              icon={<DatabaseIcon />}
              label={t('Routes.database')}
            />
          )}

          {appConfig?.pages?.anomalies && (
            <NavBarLink path={ROUTES.anomalies} label={t('Routes.anomalies')} />
          )}

          {appConfig?.pages?.open_api_docs && !hideElements() && (
            <NavBarLink
              path={ROUTES.openApiDocumentation}
              icon={<ApiIcon />}
              label={t('Routes.docs')}
            />
          )}

        </ul>
      </nav>
    </aside>
  );
};

export default NavBar;
