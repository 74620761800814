import { getEnvironmentVariables } from '../environment';
import { obtainTokenApim } from './tokenLogin';
import appApi from './appApi';
import { AxiosResponse } from 'axios';
import { getISODateWithoutMilliseconds } from '../../shared/utils/dateHelpers';


const getAPIMToken = async () => {
    const { enableAPIManagement } = getEnvironmentVariables();
    if (enableAPIManagement) {
      const tokenResult = await obtainTokenApim();
      localStorage.setItem('token', tokenResult);
    }
  };


  const getRecommendations = async (dateFrom: Date, dateTo: Date): Promise<AxiosResponse<any, any>> => {
    await getAPIMToken();
    const result = await appApi.get('control_room_recommendations', {
        params: {
            date_from: getISODateWithoutMilliseconds(dateFrom),
            date_to: getISODateWithoutMilliseconds(dateTo),
        },
    });
    return result;
  };



  export {
    getRecommendations,

  }