import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './Button.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'contained' | 'text' | 'outlined' | 'none';
  className?: string;
  disabled?: boolean;
}

const Button = ({
  type = 'contained',
  children,
  onClick,
  className,
  disabled = false,
}: Props) => {
  return (
    <button
      className={classNames(styles.button, styles[type], className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
