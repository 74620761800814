import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';

import { DropdownItem, DropdownMenu, DropdownSubMenu } from '../DropdownMenu';
import CheckBox from '../Checkbox/Checkbox';
import PopupIconButton from '../IconButton/PopupIconButton';

import { FilterOption, Filters } from './types';

import filterNames from '../../shared/constants/filterNames';
import { ReactComponent as FilterIcon } from '../../shared/assets/images/filterIcon.svg';

import styles from './TableFilters.module.scss';

interface Props {
  updateFilters: (newFilters: (oldState: Filters) => Filters) => void;
  filters: Filters;
  filtersOptions: Array<FilterOption>;
}

const TableFilters = ({ updateFilters, filters, filtersOptions }: Props) => {
  const { t } = useTranslation();
  const switchFilter = useCallback(
    (accessor, filter) => {
      updateFilters((oldState: Filters) => {
        const nextFilters: Filters = {
          ...oldState,
          [accessor]: {
            ...oldState[accessor],
            [filterNames.all]: false,
            [filter]: !oldState[accessor][filter],
          },
        };

        if (
          filter === filterNames.all ||
          keys(pickBy(nextFilters[accessor])).length === 0
        ) {
          nextFilters[accessor] = { [filterNames.all]: true };
        }

        return nextFilters;
      });
    },
    [updateFilters],
  );

  return (
    <PopupIconButton
      icon={<FilterIcon />}
      tooltip={t('Table.filtersIconTooltip')}
      testKey="table-filters"
    >
      <DropdownMenu open={true}>
        {filtersOptions.map(submenu => (
          <DropdownSubMenu name={submenu.name} key={submenu.key}>
            {submenu.items.map(filter => {
              return (
                <DropdownItem key={'filter-item-' + filter.key}>
                  <div
                    key={filter.key}
                    className={styles.filterCheckbox}
                    data-testid="table-filter-checkbox"
                  >
                    <CheckBox
                      onChange={() => switchFilter(submenu.key, filter.key)}
                      checked={!!filters[submenu.key][filter.key]}
                      label={filter.name}
                    />
                  </div>
                </DropdownItem>
              );
            })}
          </DropdownSubMenu>
        ))}
      </DropdownMenu>
    </PopupIconButton>
  );
};

export default TableFilters;
