import React, { useContext } from 'react';

import NavBar from '../NavBar/NavBar';
import Settings from '../Settings/Settings';

import DemoContext from '../../shared/hooks/DemoContext';

import { ReactComponent as LogoIcon } from '../../shared/assets/images/lingoIcon.svg';

import styles from './SideBar.module.scss';

const SideBar = () => {
  const { demoConfig } = useContext(DemoContext);

  return (
    <div className={styles.container}>
      <span className={styles.topPart}>
        <span className={styles.logo}>
          {demoConfig.logo ? (
            <img alt="Logo" className={styles.userLogo} src={demoConfig.logo} />
          ) : (
            <LogoIcon />
          )}
        </span>

        <NavBar />
      </span>

      <div className={styles.buttonsContainer}>
        {/* <Notes /> */}
        <Settings />
      </div>
    </div>
  );
};

export default SideBar;
