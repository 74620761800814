import React from 'react';
import classNames from 'classnames';
import styles from './RecommendationTableHeaderGroup.module.scss';
import { ReactComponent as Arrow } from '../../../shared/assets/images/arrowIcon.svg';
import { ColumnInstance } from 'react-table';

const getTitleStyles = (titleAlign: string, isSorted: boolean) =>
  classNames(
    styles.cellWrapper,
    styles['titleAlign_' + (titleAlign || 'left')],
    isSorted && styles.isSorted,
  );

const getCellStyles = (isSorted: boolean) =>
  classNames(styles.cell, isSorted && styles.sortedColumn);

const getIconStyles = (isSorted: boolean, isDesc?: boolean) =>
  classNames(styles.icon, !(isSorted && isDesc) && styles.rotate180deg);

const sortableIcon = (isSorted: boolean, isDesc?: boolean) => (
  <span className={getIconStyles(isSorted, isDesc)}>
    <Arrow className={classNames(isDesc && styles.descendingIcon)} />
  </span>
);

interface Props {
  column: ColumnInstance;
  style?: any;
}

const RecommendationHeader = ({ column, style }: Props) => {
  const { canSort, isSorted, isSortedDesc, titleAlign } = column;

  return (
    <div style={style} className={getCellStyles(isSorted)}>
      <div
        className={getTitleStyles(titleAlign, isSorted)}
        {...column.getSortByToggleProps()}
      >
        {canSort && sortableIcon(isSorted, isSortedDesc)}
        {column.render('Header')}
      </div>
    </div>
  );
};

export default RecommendationHeader;
