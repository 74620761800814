import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import config from '../../application.json';

import en from './locales/en.json';
import es from './locales/es.json';

const resources = {
  en,
  es,
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['cookie', 'localStorage'],
    },
    resources,
    fallbackLng: config.default_language,
    keySeparator: '.',
  });

export default i18n;
