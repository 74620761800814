import { getEnvironmentVariables } from '../environment';
import { obtainTokenApim } from './tokenLogin';
import appApi from './appApi';
import {
  getISODateWithoutMilliseconds,
  getDayEnd,
  getDayStart,
} from '../../shared/utils/dateHelpers';
import { AxiosResponse } from 'axios';
import qs from 'qs';

const getAPIMToken = async () => {
  const { enableAPIManagement } = getEnvironmentVariables();
  if (enableAPIManagement) {
    const tokenResult = await obtainTokenApim();
    localStorage.setItem('token', tokenResult);
  }
};

const getReport = async (
  dateFrom: Date,
  dateTo: Date,
  comment: string,
  i18n: any,
): Promise<AxiosResponse<any, any>> => {
  await getAPIMToken();
  const result = await appApi.get('report', {
    params: {
      date_from: getISODateWithoutMilliseconds(getDayStart(dateFrom)),
      date_to: getISODateWithoutMilliseconds(getDayEnd(dateTo)),
      comment,
      locale: i18n.language,
    },
  });
  return result;
};

const downloadPDFReport = async (
  dateFrom: Date,
  dateTo: Date,
  comment: string,
  i18n: any,
): Promise<AxiosResponse<any, any>> => {
  await getAPIMToken();
  const result = await appApi.get('report/pdf', {
    responseType: 'arraybuffer',
    params: {
      date_from: getISODateWithoutMilliseconds(getDayStart(dateFrom)),
      date_to: getISODateWithoutMilliseconds(getDayEnd(dateTo)),
      comment: comment,
      locale: i18n.language,
    },
  });
  return result;
};

const sendReport = async (
  dateFrom: Date,
  dateTo: Date,
  comment: string,
  i18n: any,
  recipients: Array<string>,
): Promise<AxiosResponse<any, any>> => {
  await getAPIMToken();
  const result = await appApi.get('report/mail', {
    params: {
      date_from: getISODateWithoutMilliseconds(getDayStart(dateFrom)),
      date_to: getISODateWithoutMilliseconds(getDayEnd(dateTo)),
      recipients: recipients,
      comment: comment,
      locale: i18n.language,
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return result;
};

export { getReport, downloadPDFReport, sendReport };
