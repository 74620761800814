import React, { useEffect } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import CurrentRunSummary from '../../components/CurrentRunSummary/CurrentRunSummary';
import RecommendationsView from '../../components/RecommendationsView/RecommendationsView';
import CommonError from '../../custom_components/CommonError/CommonError';
import Loading from '../../custom_components/Loading/Loading';

import { getEnvironmentVariables } from '../../shared/environment';
import { getLoginRequest } from '../../shared/api/authConfig';

const AnalyticsContent = () => (
  <>
    <CurrentRunSummary />
    <RecommendationsView />
  </>
);

const Analytics = () => {
  const { enableADIntegration } = getEnvironmentVariables();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      const dashboardHours = (hours + 19) % 24;

      if (
        (dashboardHours === 7 && minutes <= 59)  ||
        (dashboardHours === 11 && minutes <= 59) ||
        (dashboardHours === 15 && minutes <= 59) ||
        (dashboardHours === 19 && minutes <= 59) ||
        (dashboardHours === 23 && minutes <= 59) ||
        (dashboardHours === 3 && minutes <= 59)
      ) {
        window.location.reload();
      }
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  if (!enableADIntegration) {
    return <AnalyticsContent />;
  }

  const authRequest = getLoginRequest();

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={CommonError}
      loadingComponent={Loading}
    >
      <AnalyticsContent />
    </MsalAuthenticationTemplate>
  );
};

export default Analytics;
