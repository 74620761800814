import { useEffect, useState, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';

import Main from './Main';

import SessionContext from '../../shared/contexts/SessionContext';
import { getEnvironmentVariables } from '../../shared/environment';
import AppAD from '../../shared/api/appAD';
import { obtainTokenApim } from '../../shared/api/tokenLogin';

import styles from './App.module.scss';

const AppRoutes = () => {
  const { enableAPIManagement } = getEnvironmentVariables();

  const msalInstance: IPublicClientApplication = AppAD();
  const [token, setToken] = useState<string | null>(null);
  const { setUser, setAuthTokenFetched } = useContext(SessionContext);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setToken(localStorage.getItem('token'));
    }
    // TODO: Analyze complexity for this function in UseEffect
    const handleRedirect = async () => {
      try {
        let authToken = '';
        const response = await msalInstance.handleRedirectPromise();
        if (response) {
          const msalAuthResponse = await msalInstance.acquireTokenSilent({
            scopes: ['user.read'],
            // account: msalInstance.getActiveAccount(),
          });

          // Set user information
          setUser({
            profile: msalAuthResponse.account.username,
            userName: msalAuthResponse.account.name || '',
          });
          localStorage.setItem(
            'user_name',
            msalAuthResponse.account.name || '',
          );

          if (msalAuthResponse.accessToken) {
            localStorage.setItem('token', msalAuthResponse.accessToken);
            localStorage.setItem('token_ad', msalAuthResponse.accessToken);
            authToken = msalAuthResponse.accessToken;

            if (enableAPIManagement) {
              const token_apim = await obtainTokenApim();
              localStorage.setItem('token', token_apim);
              localStorage.setItem('token_apim', token_apim);
              authToken = token_apim;
            }

            setToken(authToken);
            setAuthTokenFetched(true);
          }
        }
      } catch (error) {
        console.error('Authentication error:', error);
      }
    };

    handleRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Analyze this scenario because when is enable, msalInstance.handleRedirectPromise is not working
  // if (!token) {
  //   return <div data-testid="NoAuthentication">Loading...</div>;
  // }

  return (
    <BrowserRouter>
      <section className={styles.appContent}>
        <Main />
      </section>
    </BrowserRouter>
  );
};

export default AppRoutes;
