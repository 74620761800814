import classNames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../Card/Card';
import ContentPlaceholder from '../ContentPlaceholder/ContentPlaceholder';
import PredictionView from '../PredictionView/PredictionView';
import { Prediction } from '../PredictionView/types';

import { getPredictions } from '../../shared/api/analytics';
import { AppContext } from '../../shared/AppContext';
import { useFetchData } from '../../shared/hooks/useFetchData';
import { useTargetColors } from '../../shared/hooks/useTargetColors';

import styles from './CurrentRunSummary.module.scss';

const CurrentRunSummary = () => {
  const [predictions, , isDataFetched] = useFetchData<Prediction[]>(
    getPredictions,
    [],
  );

  const { t } = useTranslation();
  const targetColors = useTargetColors();

  const { dynamicRecsLoading } = useContext(AppContext);

  return (
    <div className={styles.container}>
      <ContentPlaceholder
        isLoading={!isDataFetched}
        loaderClassName={styles.loaderPlaceholder}
        doesContentExist={predictions.length > 0}
        noContentTitle={t('CurrentRunSummary.noData')}
      >
        {predictions.map(prediction => {
          const color = targetColors[prediction.target_id]?.color;

          if (!color) return null;

          return (
            <Card
              key={`prediction-${prediction.name}`}
              style={{ borderTopColor: color.primary }}
              className={classNames(
                styles.cardContainer,
                dynamicRecsLoading && styles.skeletonLoader,
              )}
            >
              <PredictionView prediction={prediction} color={color} />
            </Card>
          );
        })}
      </ContentPlaceholder>
    </div>
  );
};

export default CurrentRunSummary;
