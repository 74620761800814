import React from 'react';
import { HeaderProps } from 'react-table';
import styles from './SelectAll.module.scss';
import Checkbox from '../../Checkbox/Checkbox';

const SelectAll = ({
  cell,
  disabled,
}: {
  cell: HeaderProps<any>;
  disabled: boolean;
}) => (
  <div className={styles.wrapper}>
    <Checkbox disabled={disabled} {...cell.getToggleAllRowsSelectedProps()} />
  </div>
);

export default SelectAll;
