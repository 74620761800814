const shortForms: [number, string][] = [
  [1_000_000_000_000, 'T'],
  [1_000_000_000, 'B'],
  [1_000_000, 'M'],
  [1000, 'K'],
];

export function formatToShortNumber(num: number): string {
  for (const [threshold, abbreviation] of shortForms) {
    if (Math.abs(num) >= threshold) {
      const result = num / threshold;

      return `${
        Number.isInteger(result) ? result : result.toFixed(1)
      }${abbreviation}`;
    }
  }

  return Number.isInteger(num) ? num.toString() : num.toFixed(2);
}

export function formatToPercentage(value: number, precision = 1) {
  const formattedValue = value > 1 ? value : value * 100;

  return `${formattedValue.toFixed(precision)}%`;
}
