import styles from './ErrorView.module.scss';
import sanitizeHTML from '../../utils/sanitizeHTML';

function ErrorView(message) {
  return `
      <div class='${styles.wrapper}'>
        <div class='${styles.body}'>
          <div class='${styles.title}'>${
    message ? sanitizeHTML(message) : 'Algo salió mal!'
  }</div>
          <div class='${styles.subtitle}'>
          Por favor, comunícate con
            <span class='${styles.email}'>jorge.infantes@marcobre.com</span>
          </div>
        </div>
      </div>
    `;
}

export default ErrorView;
