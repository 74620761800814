import { AxiosResponse } from 'axios';
import appApi from './appApi';
import { obtainTokenApim } from './tokenLogin';

import { Prediction } from '../../components/PredictionView/types';
import { Recommendation } from '../../components/RecommendationTable/types';
import { RecommendationUpdateBody } from '../../shared/types/recommendationUpdateBody';
import {
  getDayEnd,
  getDayStart,
  getISODateWithoutMilliseconds,
} from '../../shared/utils/dateHelpers';

import { getEnvironmentVariables } from '../environment';

const getAPIMToken = async () => {
  const { enableAPIManagement } = getEnvironmentVariables();
  if (enableAPIManagement) {
    const tokenResult = await obtainTokenApim();
    localStorage.setItem('token', tokenResult);
  }
};

const getPredictions = async (): Promise<AxiosResponse<Prediction[], any>> => {
  await getAPIMToken();
  const result = await appApi.get('/control_room_predictions');
  return result;
};

const getControlRoomRecommendations = async (): Promise<
  AxiosResponse<Recommendation[], any>
> => {
  await getAPIMToken();
  const result = await appApi.get('/control_room_recommendations');
  return result;
};

const updateRecommendationStatuses = async (
  recommendations: RecommendationUpdateBody[],
): Promise<Recommendation[]> => {
  await getAPIMToken();
  const result = await appApi.put(`/recommendations/status`, recommendations);
  return result.data;
};

const getTargets = async () => {
  await getAPIMToken();
  const result = await appApi.get('/targets');
  return result;
};

const getRecommendationsUpdateTime = async (): Promise<
  AxiosResponse<{ updated_at: string } | undefined>
> => {
  await getAPIMToken();
  const result = await appApi.get('/recommendations_update_time');
  return result;
};

const getChartData = async (sensorId: string, paramDateFrom: Date, dateTo: Date) => {
  await getAPIMToken();
  const { data } = await appApi.get(`/measurements/${sensorId}`, {
    params: {
      date_from: getISODateWithoutMilliseconds(getDayStart(paramDateFrom)),
      date_to: getISODateWithoutMilliseconds(getDayEnd(dateTo)),
    },
  });
  return data;
};

export {
  getChartData,
  getPredictions,
  getControlRoomRecommendations,
  getTargets,
  getRecommendationsUpdateTime,
  updateRecommendationStatuses,
};
