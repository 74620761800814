import React from 'react';
import Checkbox from '../../Checkbox/Checkbox';
import styles from './Selector.module.scss';
import { Cell } from 'react-table';

const Selector = ({
  cell: { row },
  disabled,
}: {
  cell: Cell<any>;
  disabled: boolean;
}) => {
  return (
    <div className={styles.wrapper}>
      <Checkbox
        disabled={disabled}
        checked={row.isSelected}
        onChange={() => {
          if (row.isExpanded) row.toggleRowExpanded(false);
          row.toggleRowSelected();
        }}
      />
    </div>
  );
};

export default Selector;
