import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button/Button';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import PopupIconButton, {
  preventPopupClosingOnClick,
} from '../IconButton/PopupIconButton';
import Tooltip from '../Tooltip/Tooltip';

import analyticsApi from '../../shared/api/appApi';
import { ReactComponent as DownloadIcon } from '../../shared/assets/images/downloadIcon.svg';
import { callWithErrorHandler } from '../../shared/services/ErrorHandler/callWithErrorHandler';
import {
  getDayEnd,
  getDayStart,
  getISODateWithoutMilliseconds,
} from '../../shared/utils/dateHelpers';
import { downloadFile } from '../../shared/utils/downloadFile';

import styles from './DownloadMenu.module.scss';

const DownloadMenu = () => {
  const [isDatePickerFromVisible, setDatePickerFromVisible] = useState(false);
  const [isDatePickerToVisible, setDatePickerToVisible] = useState(false);
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const disabled = !(dateFrom && dateTo);
  const { t } = useTranslation();

  useEffect(() => {
    if (!dateFrom) return;
    setDatePickerFromVisible(false);
  }, [dateFrom]);

  useEffect(() => {
    if (!dateTo) return;
    setDatePickerToVisible(false);
  }, [dateTo]);

  useEffect(() => {
    if (!dateTo && !dateFrom) return;

    if (!dateTo) setDatePickerToVisible(true);
    if (!dateFrom) setDatePickerFromVisible(true);
  }, [dateTo, dateFrom]);

  const resetDates = useCallback(() => {
    setDateFrom(undefined);
    setDateTo(undefined);
  }, []);

  useEffect(() => {
    resetDates();
  }, [resetDates]);

  const downloadRecommendations = async () => {
    if (!dateFrom || !dateTo) return;

    callWithErrorHandler(async () => {
      const response = await analyticsApi.get('/recommendations/download', {
        params: {
          date_from: getISODateWithoutMilliseconds(getDayStart(dateFrom)),
          date_to: getISODateWithoutMilliseconds(getDayEnd(dateTo)),
        },
      });

      downloadFile(response);
    });
  };

  return (
    <div className={styles.container}>
      <PopupIconButton
        icon={<DownloadIcon />}
        tooltip={t('DownloadMenu.iconTooltip')}
        onToggle={resetDates}
      >
        <div className={styles.menu}>
          <div onClick={preventPopupClosingOnClick}>
            <DatePickerInput
              text={t('DownloadMenu.fromDate')}
              dateToDisplay={dateFrom}
              selectDate={setDateFrom}
              dateFrom={dateFrom}
              dateTo={dateTo}
              disabledDays={dateTo && { after: dateTo }}
              datePickerClassName={styles.datePickerFrom}
              inputClassName={styles.datePickerInput}
              isDatePickerVisible={isDatePickerFromVisible}
              setDatePickerVisible={setDatePickerFromVisible}
            />

            <DatePickerInput
              text={t('DownloadMenu.toDate')}
              dateToDisplay={dateTo}
              selectDate={setDateTo}
              dateFrom={dateFrom}
              dateTo={dateTo}
              disabledDays={dateFrom && { before: dateFrom }}
              datePickerClassName={styles.datePickerTo}
              inputClassName={styles.datePickerInput}
              isDatePickerVisible={isDatePickerToVisible}
              setDatePickerVisible={setDatePickerToVisible}
            />
          </div>

          <div className={styles.downloadButtonContainer}>
            <Tooltip
              text={t('DownloadMenu.submitTooltip')}
              disabled={!disabled}
              position="bottom"
              className={styles.downloadButtonTooltip}
            >
              <Button
                disabled={disabled}
                onClick={downloadRecommendations}
                type="outlined"
              >
                {t('DownloadMenu.submit')}
              </Button>
            </Tooltip>
          </div>
        </div>
      </PopupIconButton>
    </div>
  );
};

export default DownloadMenu;
