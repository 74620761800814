import { TFunction } from 'i18next';
import groupBy from 'lodash/groupBy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';
import { getStatusColor } from '../../../shared/constants/statuses';
import {
  TargetColors,
  useTargetColors,
} from '../../../shared/hooks/useTargetColors';
import HorizontalBarChart from '../../HorizontalBarChart/HorizontalBarChart';
import { Recommendation } from '../../RecommendationTable/types';
import Table from '../../Table/Table';
import PerformanceTotalCountChart from '../PerformanceTotalCountChart/PerformanceTotalCountChart';
import styles from './PerformanceTable.module.scss';

interface Props {
  data: Recommendation[];
  isDataFetched?: boolean;
  filter: { group: string; value: string };
  statuses: Array<string>;
}

const HeaderCell = ({ title, color }: { title: string; color: string }) => (
  <div className={styles.header}>
    <div className={styles.title} style={{ color: color }}>
      {title}
    </div>
    <div className={styles.square} style={{ backgroundColor: color }} />
  </div>
);

const getColumnsForFilter = (
  filterGroup: string,
  t: TFunction,
  targetColors: TargetColors,
) => {
  const selectiveColumns = [];

  if (filterGroup === 'target_id') {
    selectiveColumns.push({
      Header: t('Performance.area'),
      accessor: 'area',
    });
  }

  if (filterGroup === 'area') {
    selectiveColumns.push({
      Header: t('Performance.target'),
      accessor: 'target_id',
      Cell: (cell: Cell) => {
        const targetName = targetColors[cell.value]
          ? targetColors[cell.value].name
          : cell.value;
        return <div>{targetName}</div>;
      },
    });
  }

  return selectiveColumns;
};

const PerformanceTable = ({ data, filter, isDataFetched, statuses }: Props) => {
  const { t } = useTranslation();
  const targetColors = useTargetColors();

  const recommendationsTitle =
    filter.group === 'target_id'
      ? targetColors[filter.value].name
      : filter.value;

  const columns = [
    {
      Header: () => (
        <div className={styles.recommendationsTitle}>
          <span className={styles.boldText}>{recommendationsTitle}</span>{' '}
          {data.length} {t('Performance.recommendations')}
        </div>
      ),
      accessor: 'recommendation',
    },
    {
      Header: '',
      Cell: (cell: Cell) => {
        const values = cell.row.values;

        const data = statuses.map(name => ({
          value: values[name],
          color: getStatusColor(name),
        }));

        return (
          <div className={styles.verticalBarWrapper}>
            <HorizontalBarChart data={data} />
          </div>
        );
      },
      accessor: 'chart',
      sortable: false,
    },
    ...getColumnsForFilter(filter.group, t, targetColors),
    ...statuses.map(status => ({
      Header: (
        <HeaderCell
          color={getStatusColor(status)}
          title={t(`Statuses.${status}`)}
        />
      ),
      Cell: (cell: Cell) => (
        <div className={styles.statusCount}>{cell.value}</div>
      ),
      accessor: status,
      titleAlign: 'center',
      width: 80,
    })),
  ];

  // group all recommendations by tag
  const groupedByTag = groupBy(data, n => n.tag_id);

  // prepare data for table and get statistics about statuses count in every group
  const serializedData = Object.values(groupedByTag).map(
    (arr: Recommendation[]) => ({
      id: arr[0].id,
      recommendation: arr[0].parameters,
      area: arr[0].area,
      target_id: arr[0].target_id,
      ...statuses.reduce<{ [key: string]: number }>((acc, el) => {
        acc[el] = arr.filter(s => s.status === el).length;
        return acc;
      }, {}),
    }),
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.chartWrapper}>
        <PerformanceTotalCountChart
          statuses={statuses}
          recommendations={data}
        />
      </div>
      <Table
        isDataFetched={isDataFetched}
        data={serializedData}
        columns={columns}
        tableStyles={styles.tableOverrides}
        rowClassName={styles.tableRow}
      />
    </div>
  );
};

export default PerformanceTable;
