import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import PerformanceSummary from '../../components/PerformanceSummary/PerformanceSummary';
import CommonError from '../../custom_components/CommonError/CommonError';
import Loading from '../../custom_components/Loading/Loading';

import { getEnvironmentVariables } from '../../shared/environment';
import { getLoginRequest } from '../../shared/api/authConfig';

const PerformanceContent = () => <PerformanceSummary />;

const Performance = () => {
  const { enableADIntegration } = getEnvironmentVariables();

  if (!enableADIntegration) {
    return <PerformanceContent />;
  }

  const specificUrl = '/performance';
  const authRequest = getLoginRequest(specificUrl);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={CommonError}
      loadingComponent={Loading}
    >
      <PerformanceContent />
    </MsalAuthenticationTemplate>
  );
};

export default Performance;
