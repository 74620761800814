import React, { MouseEventHandler, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '../../Button/Button';
import PopupMenu from '../../PopupMenu/PopupMenu';
import RadioButton from '../../RadioButton/RadioButton';
import { Recommendation } from '../../RecommendationTable/types';
import StatusIcon from '../../StatusIcon/StatusIcon';

import STATUSES from '../../../shared/constants/statuses';
import { formatToShortNumber } from '../../../shared/utils/formatNumbers';


import { ReactComponent as ShortArrow } from '../../../shared/assets/images/shortArrow.svg';
import styles from './StatusButtons.module.scss';
import { getEnvironmentVariables } from '../../../shared/environment';

interface Props {
  handleStatusChange: (status: string, newValue?: number) => void;
  recommendation?: Recommendation;
}
interface StatusButtonProps {
  status: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  content?: ReactNode;
}
export function StatusButton({ status, onClick, content }: StatusButtonProps) {
  const { t } = useTranslation();

  return (
    <Button onClick={onClick || (() => { })} className={styles.statusButton}>
      <StatusIcon showEmptyIcon={false} status={status} />
      {t(`Statuses.${status}Button`)}
      {content}
    </Button>
  );
}
export function StatusButtons({ handleStatusChange, recommendation }: Props) {
  const { t } = useTranslation();
  const [showPossibleValues, setShowPossibleValues] = useState(false);

  const isDynamicRecsEnabled = process.env.REACT_APP_USE_DYNAMIC_RECS;
  const { enableBTNReview } = getEnvironmentVariables();

  return (
    <div className={styles.container}>
      {isDynamicRecsEnabled && recommendation ? (
        <PopupMenu
          button={
            <StatusButton
              status={STATUSES.declined}
              content={
                <div
                  className={classNames(
                    styles.openPossibleValuesIcon,
                    !showPossibleValues && styles.iconRotated,
                  )}
                >
                  <ShortArrow className={styles.arrowIcon} />
                </div>
              }
            />
          }
          onToggle={setShowPossibleValues}
          childrenClassName={styles.possibleValuesPopup}
        >
          <ul className={styles.possibleValues}>
            <li className={styles.dropdownItemTitle}>
              {t('RecommendationDetails.possibleValuesTitle')}
            </li>

            {recommendation.possible_values.map((value, index) => (
              <li
                className={styles.dropdownItemClickable}
                key={`${value}${index}`}
                onClick={() => handleStatusChange(STATUSES.declined, value)}
              >
                <RadioButton
                  preventBubbling={false}
                  label={
                    <>
                      {formatToShortNumber(value)}
                      <span className={styles.recommendationUnit}>
                        {recommendation.unit}
                      </span>
                    </>
                  }
                />
              </li>
            ))}

            <li
              className={classNames(
                styles.dropdownItemClickable,
                styles.declineOption,
              )}
              onClick={() =>
                handleStatusChange(STATUSES.declined, recommendation.current)
              }
            >
              <RadioButton
                preventBubbling={false}
                label={t('RecommendationDetails.lastValue')}
              />
            </li>
          </ul>
        </PopupMenu>

      ) : (
        <StatusButton
          status={STATUSES.approved}
          onClick={() => handleStatusChange(STATUSES.approved)}
        />
      )}
      <StatusButton
        status={STATUSES.declined}
        onClick={() => handleStatusChange(STATUSES.declined)}
      />
    
      {enableBTNReview == true && (
        <StatusButton
          status={STATUSES.inreview}
          onClick={() => handleStatusChange(STATUSES.inreview)}
        />
      ) }
    </div>
  );
}
