import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getActualsData, getPredictionsData } from '../../shared/api/impactTracking';
import {
  ChartPoint,
  useChart,
  UseChartDataset,
  UseChartOptions,
} from '../../shared/hooks/useChart';
import { callWithErrorHandler } from '../../shared/services/ErrorHandler/callWithErrorHandler';
import ThemeContext from '../../shared/themes/ThemeContext';
import { Target } from '../../shared/types/target';
import { sampleTimeSeriesData } from '../../shared/utils/sampleTimeSeriesData';
import styles from './ImpactTracker.module.scss';
import { TargetActual, TargetPrediction } from './types';
import ContentPlaceholder from '../ContentPlaceholder/ContentPlaceholder';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const getImpactTrackingData = async (
  target: Target,
  dateFrom: Date,
  dateTo: Date,
) => {
 
  const { data: actualsData } = await getActualsData(target,dateFrom,dateTo);
  const { data: predictionsData } = await getPredictionsData(target,dateFrom,dateTo);

  const actuals = sampleTimeSeriesData<TargetActual>(actualsData);
  const predictions = sampleTimeSeriesData<TargetPrediction>(predictionsData);

  return {
    actuals: actuals.map<ChartPoint>(value => ({
      x: value.timestamp,
      y: value.actual,
    })),
    baselines: predictions.map<ChartPoint>(value => ({
      x: value.timestamp,
      y: value.baseline,
    })),
    optimized: predictions.map<ChartPoint>(value => ({
      x: value.timestamp,
      y: value.optimized,
    })),
  };
};

interface Props {
  target: Target;
  dateFrom?: Date;
  dateTo?: Date;
}

const chartOptions: UseChartOptions = {
  xAxisTicksSource: 'data',
  yAxisMaxTicksLimit: 4,
  textColorVariable: '--text-color',
  gridColorVariable: '--border-color',
};

const ImpactTracker = ({ target, dateFrom, dateTo }: Props) => {
  const { t } = useTranslation();
  const { getCSSVariable } = useContext(ThemeContext);

  const [chartData, setChartData] = useState<UseChartDataset[]>([]);
  const [isDataFetched, setDataFetched] = useState(false);
  const [updateCanvasRef] = useChart(chartData, chartOptions);

  useEffect(() => {
    if (!dateFrom || !dateTo) return;

    callWithErrorHandler(async () => {
      const actualColor = getCSSVariable('--actual-color');
      const optimizedColor = getCSSVariable('--optimized-color');
      const baselineColor = getCSSVariable('--baseline-color');
      const fillColor = getCSSVariable('--selection-color');

      setDataFetched(false);

      const { actuals, optimized, baselines } = await getImpactTrackingData(
        target,
        dateFrom,
        dateTo,
      );

      setChartData([
        {
          data: actuals,
          fill: false,
          borderColor: actualColor,
          hoverBorderColor: actualColor,
          borderWidth: 1.5,
          pointBackgroundColor: actualColor,
          hoverBackgroundColor: actualColor,
          pointRadius: 3,
          tension: 0.4,
        },
        {
          data: optimized,
          fill: '+1',
          backgroundColor: fillColor,
          borderColor: optimizedColor,
          hoverBorderColor: optimizedColor,
          borderWidth: 1.5,
          pointBackgroundColor: optimizedColor,
          hoverBackgroundColor: optimizedColor,
          pointRadius: 2,
          tension: 0.4,
        },
        {
          data: baselines,
          fill: false,
          borderDash: [5, 5],
          borderColor: baselineColor,
          hoverBorderColor: baselineColor,
          borderWidth: 1.5,
          pointBackgroundColor: baselineColor,
          hoverBackgroundColor: baselineColor,
          pointRadius: 2,
          tension: 0.4,
        },
      ]);

      setDataFetched(true);
    });
  }, [target, dateFrom, dateTo, getCSSVariable]);

  const doesContentExist = chartData.some(dataset => dataset.data.length > 0);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>
          {target.name} {target.unit !== '' && `(${target.unit})`}
        </div>
        <div className={styles.legends}>
          <div className={styles.legend}>
            <div className={styles.actual} />
            <div className={styles.legendLabel}>
              {t('ImpactTracking.actual')}
            </div>
          </div>

          <div className={styles.legend}>
            <div className={styles.optimized} />
            <div className={styles.legendLabel}>
              {t('ImpactTracking.optimized')}
            </div>
          </div>

          <div className={styles.legend}>
            <div className={styles.baseline} />
            <div className={styles.legendLabel}>
              {t('ImpactTracking.baseline')}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.zoomContainer}>
        <TransformWrapper>
          <TransformComponent>
            <div className={styles.valueChart}>
              <ContentPlaceholder
                isLoading={!isDataFetched}
                doesContentExist={doesContentExist}
                noContentTitle={t('ImpactTracking.noTargetData')}
              >
                <canvas ref={updateCanvasRef} className={styles.canvas} />
              </ContentPlaceholder>
            </div>
          </TransformComponent>
        </TransformWrapper>
      </div>
    </div>
  );
};

export default ImpactTracker;
