import React, { ReactNode } from 'react';
import styles from './Switch.module.scss';
import classNames from 'classnames';

interface Props {
  checked: boolean;
  onChange: (e: any) => void;
  label?: string | ReactNode;
  className?: string;
}

const Switch = ({ checked, onChange, label, className }: Props) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {label && <span className={styles.label}>{label}</span>}
      <label className={styles.switch}>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className={classNames(styles.slider, styles.round)} />
      </label>
    </div>
  );
};

export default Switch;
