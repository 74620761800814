import {
  Chart,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { EventType } from '@azure/msal-browser';

import App from './App';

import { AppContextProvider } from './shared/AppContext';
import { ThemeProvider } from './shared/themes/ThemeContext';
import { SessionProvider } from './shared/contexts/SessionContext';

import './shared/i18n/i18n';
import * as serviceWorker from './shared/serviceWorker';
import AppAD from './shared/api/appAD';

import { getEnvironmentVariables } from './shared/environment';

import './shared/styles/index.scss';

Chart.register(
  LineElement,
  PointElement,
  LineController,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  Filler,
  Tooltip,
);

const { enableADIntegration } = getEnvironmentVariables();

const renderApp = msalInstance => {
  ReactDOM.render(
    <ThemeProvider>
      <SessionProvider>
        <AppContextProvider>
          <App pca={msalInstance} />
        </AppContextProvider>
      </SessionProvider>
    </ThemeProvider>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
};

let msalInstance = null;
if (enableADIntegration) {
  msalInstance = AppAD();

  msalInstance.initialize().then(() => {
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback(event => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload.account
      ) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      }
    });

    renderApp(msalInstance);
  });
} else {
  renderApp();
}

export { msalInstance };
