import { AxiosResponse } from 'axios';

export const downloadFile = (response: AxiosResponse, name = 'unknown') => {
  const rawName = response.headers['content-disposition'];

  if (rawName) {
    name = rawName.split('filename=')[1].replace(/"/g, '');
  }

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
