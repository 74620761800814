import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import PopupMenu from '../../PopupMenu/PopupMenu';
import { concatComments } from './concatComments';

import styles from './CommentTextarea.module.scss';

interface Props {
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
}

export function CommentTextarea({ comment, setComment }: Props) {
  const { t } = useTranslation();

  const updateCommentWith = (newComment: string) =>
    setComment(oldComment => concatComments(oldComment, newComment));

  const predefinedComments = t('Comment.predefinedComments', {
    returnObjects: true,
  }) as string[];

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('RecommendationDetails.comment')}:</div>

      <PopupMenu
        childrenClassName={styles.popupComments}
        button={
          <textarea
            data-testid="comment-textarea"
            className={styles.textarea}
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
        }
      >
        <ul className={styles.predefinedComments} tabIndex={-1}>
          {predefinedComments.map((comment, index) => (
            <li
              onClick={() => updateCommentWith(comment)}
              className={styles.predefinedComment}
              key={`${comment}${index}`}
              tabIndex={0}
            >
              {comment}
            </li>
          ))}
        </ul>
      </PopupMenu>
    </div>
  );
}
