import lowerFirst from 'lodash/lowerFirst';
import trimEnd from 'lodash/trimEnd';
import upperFirst from 'lodash/upperFirst';

export function concatComments(string: string, newString: string) {
  const trimmedString = trimEnd(string);
  const lastSymbol = trimmedString.slice(-1);

  if (string.length === 0) {
    return upperFirst(newString);
  }

  if (['.', '!', '?'].includes(lastSymbol)) {
    return `${string} ${upperFirst(newString)}`;
  }

  return `${string} ${lowerFirst(newString)}`;
}
