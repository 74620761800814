import classNames from 'classnames';
import React from 'react';
import { ReactComponent as ApprovedIcon } from '../../shared/assets/images/approvedIcon.svg';
import { ReactComponent as DeclinedIcon } from '../../shared/assets/images/declinedIcon.svg';
import STATUSES from '../../shared/constants/statuses';
import styles from './StatusIcon.module.scss';

interface StatusIconProps {
  status: string;
  className?: string;
  showEmptyIcon?: boolean;
}

function StatusIcon({
  status,
  className,
  showEmptyIcon = true,
}: StatusIconProps) {
  const iconClassNames = classNames(styles.icon, className);

  const icon = {
    [STATUSES.approved]: <ApprovedIcon className={iconClassNames} />,
    [STATUSES.declined]: <DeclinedIcon className={iconClassNames} />,
  }[status];

  if (icon) return icon;

  return <div className={classNames(showEmptyIcon && iconClassNames)} />;
}

export default StatusIcon;
