import React, { ReactNode, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, HeaderProps, Row, TableInstance } from 'react-table';
import { TargetColors } from '../../shared/hooks/useTargetColors';
import {
  boolSorting,
  doesRecommendationHasChanges,
  includesFilter,
} from '../../shared/utils/tableHelper';
import DownloadMenu from '../DownloadMenu/DownloadMenu';
import RecommendationDetails from '../RecommendationDetails/RecommendationDetails';
import RecommendationsCount from '../RecommendationsView/RecommendationsCount/RecommendationsCount';
import { HandleStatusChangeProps } from '../RecommendationsView/RecommendationsView';
import useRecommendationFilters from '../RecommendationTable/useRecommendationFilters';
import Status from '../Status/Status';
import { StatusButtons } from '../StatusChange/StatusButtons/StatusButtons';
import Subheader from '../Subheader/Subheader';
import Table from '../Table/Table';
import { Changes, Selector, ValueField } from '../TableCells';
import TableFilters from '../TableFilters/TableFilters';
import { ParameterValue, SelectAll } from '../TableHeaders';
import styles from './RecommendationTable.module.scss';
import { Recommendation } from './types';
import STATUSES from '../../shared/constants/statuses';
import { AppContext } from '../../shared/AppContext';

interface Props {
  targetColors: TargetColors;
  recommendations: Recommendation[];
  handleStatusChange: (props: HandleStatusChangeProps) => void;
  isDataFetched: boolean;
  headerContent: ReactNode;
}

const initialSortBy = [
  { id: 'target', desc: false },
  { id: 'status', desc: true },
];

const RecommendationTable = ({
  targetColors,
  recommendations,
  handleStatusChange,
  isDataFetched,
  headerContent,
}: Props) => {
  const { t } = useTranslation();
  const { dynamicRecsLoading } = useContext(AppContext);
  const { filters, filterOptions, updateFilters } =
    useRecommendationFilters(recommendations);

  /*
    Cell and Header props - specifies Component that will be used to render this cells
    Check imports to find out where this components stored
   */

  /*
    Removing column:
    - Remove object with your column from the `columns` array below
    - If this column could be filtered, then you also need to remove it from filters,
    go to useRecommendationFilters file in the same folder as this file and remove your column from
    `initialFilters` and `filterOptions` objects
  */

  const columns = useMemo(
    () => [
      {
        id: 'target',
        Cell: (cell: Cell<Recommendation>) => (
          <div
            className={styles.targetColor}
            style={{ backgroundColor: cell.value }}
          />
        ),
        sortable: true,
        accessor: (rec: Recommendation) =>
          targetColors[rec.target_id]?.color?.primary || 'var(--gray-color)',
        width: 20,
      },
      {
        id: 'selector',
        Cell: (cell: Cell<Recommendation>) => (
          <Selector
            cell={cell}
            disabled={!!(cell.row.original.status !== STATUSES.pending)}
          />
        ),
        Header: (cell: HeaderProps<Recommendation>) => (
          <SelectAll
            cell={cell}
            disabled={!!process.env.REACT_APP_USE_DYNAMIC_RECS}
          />
        ),
        sortable: false,
        width: 40,
      },
      {
        Header: t('RecommendationsTable.status'),
        accessor: 'status',
        // custom filter
        filter: includesFilter,
        Cell: (cell: Cell) => <Status status={cell.value} />,
        width: 100,
      },
      {
        Header: () => <div className={styles.changesIcon} />,
        titleAlign: 'right',
        id: 'changes',
        // calculate if recommendation has changes in values and make property for column
        accessor: doesRecommendationHasChanges,
        Cell: Changes,
        // react-table cant sort booleans by default so we are using custom bool sorting
        sortType: boolSorting,
        width: 70,
      },
      {
        Header: t('RecommendationsTable.parameters'),
        accessor: 'parameters',
        width: 200,
      },
      {
        Header: t('RecommendationsTable.area'),
        accessor: 'area',
        // custom filter
        filter: includesFilter,
      },
      {
        Header: ParameterValue,
        titleAlign: 'center',
        title: t('RecommendationsTable.lastValue'),
        accessor: 'current',
        Cell: ValueField,
      },
      {
        Header: ParameterValue,
        titleAlign: 'center',
        title: t('RecommendationsTable.recommendedValue'),
        accessor: 'recommended',
        Cell: ValueField,
      },
      {
        Header: t('RecommendationsTable.lastComment'),
        accessor: 'comment',
        width: 350,
        Cell: (cell: Cell) => (
          <div title={cell.value} className={styles.lastComment}>
            {cell.value || '-'}
          </div>
        ),
      },
    ],
    [t, targetColors],
  );

  const modifiedRecommendations = dynamicRecsLoading
    ? recommendations.map(rec => {
        if (rec.status === STATUSES.pending) rec.isLoading = true;
        return rec;
      })
    : recommendations;

  return (
    <Table
      data={modifiedRecommendations}
      isDataFetched={isDataFetched}
      initialSortBy={initialSortBy}
      columns={columns}
      filters={filters}
      // recommendation details, rendered when you expand row
      expandContent={(row: Row<Recommendation>) => (
        <RecommendationDetails
          row={row}
          handleStatusChange={handleStatusChange}
        />
      )}
      extraContent={(props: TableInstance) =>
        Object.keys(props.selectedFlatRows).length > 0 ? (
          <Subheader
            title={
              <>
                <span className={styles.count}>
                  {Object.keys(props.selectedFlatRows).length}
                </span>{' '}
                {t('RecommendationsTable.selectedRecommendations', {
                  count: Object.keys(props.selectedFlatRows).length,
                })}
              </>
            }
            right={
              <div className={styles.statusChangeInHeader}>
                <StatusButtons
                  handleStatusChange={status => {
                    handleStatusChange({
                      recommendations: props.selectedFlatRows.map(
                        (el: Row<object>) => el.original as Recommendation,
                      ),
                      isMultipleMode: true,
                      status,
                    });
                  }}
                />
              </div>
            }
          />
        ) : (
          <Subheader
            title={<RecommendationsCount count={recommendations.length} />}
            right={
              <>
                {headerContent}

                {filterOptions && (
                  <TableFilters
                    updateFilters={updateFilters}
                    filters={filters}
                    filtersOptions={filterOptions}
                  />
                )}

                <DownloadMenu />
              </>
            }
          />
        )
      }
      tableStyles={styles.tableStyles}
    />
  );
};

export default RecommendationTable;
