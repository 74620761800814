import React from 'react';
import AnomaliesTable from '../../components/AnomaliesTable/AnomaliesTable';

const Anomalies = () => {
  return (
    <>
      <AnomaliesTable />
    </>
  );
};

export default Anomalies;
