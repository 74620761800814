import ErrorMessages from './ErrorMessages';
import ErrorView from './ErrorView';
import Notifier from '../Notifier/Notifier';
import i18n from '../../i18n/i18n';

class ErrorHandler {
  constructor() {
    window.removeEventListener('error', () => {});
    window.removeEventListener('unhandledrejection', () => {});

    window.addEventListener('error', e => {
      this.sendError(e);
    });

    window.addEventListener('unhandledrejection', e => {
      this.sendError(e);
    });
  }

  sendError(e, message = '') {
    console.error(e);

    this.renderErrorMessage(
      e && e.message === 'Network Error' ? ErrorMessages.NetworkError : '',
    );

    if (e) {
      if (e.message === 'Network Error') {
        message = ErrorMessages.NetworkError;
      } else if (e.reason && e.reason.config && e.reason.config.url) {
        message = e.reason + ': ' + e.reason.config.url;
      } else if (e.reason) {
        message = e.reason + '. ' + i18n.t('Notifications.checkConsole');
      } else {
        message = e.message + '. ' + i18n.t('Notifications.checkConsole');
      }
    }

    Notifier.show(message, { type: 'error', duration: 200000 });
  }

  renderErrorMessage(message) {
    if (!this.errorPageRendered) {
      this.errorPageRendered = true;
      const div = document.createElement('div');
      div.insertAdjacentHTML('beforeend', ErrorView(message));
      document.body.appendChild(div);

      const rootElement = document.getElementById('root');
      if (rootElement) {
        rootElement.remove();
      }
    }
  }
}

export default new ErrorHandler();
