import React, { useState, useEffect, useCallback, useRef } from 'react';
import { SankeyChartData } from './SankeyChartTypes';
import useComponentSize from '../../shared/hooks/useComponentSize';
import TooltipBody from '../Tooltip/TooltipBody';
import styles from './SankeyChart.module.scss';
import D3Chart from './D3Chart';

interface TooltipType {
  name: string;
  text: string;
  x: number;
  y: number;
}

interface Props {
  height: number;
  data: Array<SankeyChartData>;
  tooltipText?: (count: number, name: string) => string;
  id: string;
  text: string;
  options?: {
    textMargin?: number;
    rowsPadding?: number;
    minPercentage?: number;
  };
}

const SankeyChart = ({
  data,
  height,
  id,
  text,
  tooltipText,
  options = {},
}: Props) => {
  const ref = useRef(null);
  const componentSize = useComponentSize(ref.current);
  const [tooltip, setTooltip] = useState<false | TooltipType>(false);

  const showTooltip = useCallback(
    (event, item: SankeyChartData): void => {
      if (item.key !== 'none' && tooltipText) {
        const { x, y } = {
          x: (item.points[0][0] + item.points[6][0]) / 2,
          y: (item.points[6][1] + item.points[7][1]) / 2,
        };

        setTooltip({
          name: item.name,
          text: tooltipText(item.count, item.name),
          x,
          y,
        });
      }
    },
    [tooltipText],
  );

  useEffect(() => {
    D3Chart.initialize(id);
  }, [id]);

  useEffect(() => {
    D3Chart.update(
      data,
      { x: componentSize[0], y: height },
      showTooltip,
      () => setTooltip(false),
      id,
      text,
      options,
    );
  }, [componentSize, data, height, showTooltip, id, text, options]);

  return (
    <div className={styles.wrapper} ref={ref}>
      <svg width="100%" height={height} id={id} />
      {tooltip && (
        <div
          className={styles.tooltipWrapper}
          style={{ top: tooltip.y + 'px', left: tooltip.x + 'px' }}
        >
          <TooltipBody
            className={styles.tooltip}
            text={tooltip.text}
            position="top"
          />
        </div>
      )}
    </div>
  );
};

export default SankeyChart;
