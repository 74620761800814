import React, { ReactNode, useState } from 'react';

import { UserInformationResponse } from '../types';

interface ContextProps {
  user: UserInformationResponse | null;
  isLoggedOut: boolean;
  tokenFetched: boolean;
  setUser: (user: UserInformationResponse) => void;
  setIsLoggedOut: (isLoggedOut: boolean) => void;
  setAuthTokenFetched: (tokenFetched: boolean) => void;
}

const defaultState = {
  user: null,
  isLoggedOut: false,
  tokenFetched: false,
  setUser: () => {},
  setIsLoggedOut: () => {},
  setAuthTokenFetched: () => {},
};

const SessionContext = React.createContext<ContextProps>(defaultState);

const SessionProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserInformationResponse | null>(
    defaultState.user,
  );
  const [isLoggedOut, setIsLoggedOut] = useState(defaultState.isLoggedOut);
  const [tokenFetched, setAuthTokenFetched] = useState(
    defaultState.tokenFetched,
  );

  return (
    <SessionContext.Provider
      value={{
        user,
        isLoggedOut,
        tokenFetched,
        setUser,
        setIsLoggedOut,
        setAuthTokenFetched,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContext;
export { SessionProvider };
