import React, { useEffect } from 'react';
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import styles from './OpenApiDocumentation.module.scss';
import './SwaggerUIOverrides.scss';

const domNodeId = 'open-api-documentation';

const OpenApiDocumentation = () => {
  const initializeDocumentation = async () => {
    SwaggerUI({
      url: `${process.env.REACT_APP_OPTIMUS_ANALYTICS_API}/api/v1/open_api`,
      dom_id: `#${domNodeId}`,
      docExpansion: 'list',
    });
  };

  useEffect(() => {
    initializeDocumentation();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div id={domNodeId} />
    </div>
  );
};

export default OpenApiDocumentation;
