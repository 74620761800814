import React, { ReactNode, useEffect, useState, createContext } from 'react';
import { getI18n } from 'react-i18next';
import appConfig from '../../application.json';
import analyticsApi from '../api/appApi';

export interface DatabaseConfig {
  enabled?: boolean;
  use_case: string;
  language: string;
  database: string;
}

export interface DemoConfig {
  selectedDb?: DatabaseConfig;
  accentColor?: string;
  logo?: string;
}

const Languages: { [name: string]: string } = {
  english: 'en',
  russian: 'ru',
  chinese: 'zh',
  spanish: 'es',
};

interface ContextProps {
  demoConfig: DemoConfig;
  updateDemoConfig: (config: DemoConfig) => void;
}

const defaultState = {
  demoConfig: {},
  updateDemoConfig: (config: DemoConfig) => {},
};

const DemoContext = createContext<ContextProps>(defaultState);

const applyConfig = (newConfig: DemoConfig) => {
  const { selectedDb } = newConfig;

  if (selectedDb) {
    // unfortunately, we cant switch language without changing language var in locale storage,
    // so we are saving it previous value and set again after switching language, in that way after reloading page
    // we will not have language selected in demo
    const locale = localStorage.getItem('i18nextLng');
    getI18n().changeLanguage(Languages[selectedDb.language]);
    localStorage.setItem('i18nextLng', locale || '');
  }

  if (selectedDb) {
    analyticsApi.defaults.headers.common['Database-name'] = selectedDb.database;
  } else {
    analyticsApi.defaults.headers.common['Database-name'] = '';
  }
};

const DemoProvider = ({ children }: { children: ReactNode }) => {
  const [demoConfig, setDemoConfig] = useState<DemoConfig>({});

  const updateDemoConfig = (newConfig: DemoConfig) => {
    if (appConfig?.pages?.demo) {
      setDemoConfig(newConfig);
    }
  };

  useEffect(() => {
    applyConfig(demoConfig);
  }, [demoConfig]);

  const style = demoConfig.accentColor
    ? ({ '--secondary-color': demoConfig.accentColor } as React.CSSProperties)
    : {};

  return (
    <DemoContext.Provider value={{ demoConfig, updateDemoConfig }}>
      <div style={style}>{children}</div>
    </DemoContext.Provider>
  );
};

export default DemoContext;
export { DemoProvider };
