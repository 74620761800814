import { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { callWithErrorHandler } from '../services/ErrorHandler/callWithErrorHandler';

export const useFetchData = <T>(
  fetchFunction: () => Promise<AxiosResponse<T>>,
  initialState: T,
) => {
  const [data, setData] = useState<T>(initialState);
  const [isDataFetched, setDataFetched] = useState(false);
  const { serverSentEventId } = useContext(AppContext);

  useEffect(() => {
    callWithErrorHandler(async () => {
      const { data } = await fetchFunction();
      setData(data);
      setDataFetched(true);
    });
  }, [fetchFunction, serverSentEventId]);

  return [data, setData, isDataFetched] as const;
};
