import React, { useContext } from 'react';
import Tooltip from '../Tooltip/Tooltip';
import { AppContext } from '../../shared/AppContext';
import styles from './TooltipMarker.module.scss';

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  text: string;
  position?: 'top' | 'bottom';
}

const TooltipMarker = ({ children, disabled, position, text }: Props) => {
  const { showTooltipMarkers } = useContext(AppContext);

  return (
    <div className={styles.container}>
      <Tooltip
        text={text}
        position={position}
        disabled={showTooltipMarkers || disabled}
      >
        {children}
      </Tooltip>
      {showTooltipMarkers && !disabled && (
        <div className={styles.content}>
          <Tooltip text={text}>
            <div className={styles.icon}>?</div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default TooltipMarker;
