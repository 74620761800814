import React from 'react';
import classNames from 'classnames';
import styles from './Changes.module.scss';
import Tooltip from '../../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';

const Changes = ({ cell }: { cell: Cell }) => {
  const { t } = useTranslation();

  const notifier = classNames(cell.value && styles.notificationCircle);

  return (
    <div className={styles.notificationWrapper}>
      <Tooltip
        text={t('Table.changesTooltip')}
        position="bottom"
        className={styles.notificationTooltip}
      >
        <div className={notifier} />
      </Tooltip>
    </div>
  );
};

export default Changes;
