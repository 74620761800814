import React from 'react';
import { useTranslation } from 'react-i18next';
import TooltipMarker from '../../TooltipMarker/TooltipMarker';
import { getFormattedTime } from '../../../shared/utils/dateHelpers';

interface Props {
  column: {
    title: string;
  };
}

const ParameterValue = ({ column }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <TooltipMarker
      text={
        t('Table.valueTooltip') + getFormattedTime(new Date(), i18n.language)
      }
      position="bottom"
    >
      {column.title}
    </TooltipMarker>
  );
};

export default ParameterValue;
