import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import DatePeriodPicker from '../DatePeriodPicker/DatePeriodPicker';
import {
  DatePeriod,
  getDatePeriodRange,
} from '../../shared/utils/getDatePeriodRange';
import { getDayEnd, getDayStart } from '../../shared/utils/dateHelpers';
import styles from './ExtendedDatePicker.module.scss';

interface Props {
  dateTo: Date | undefined;
  dateFrom: Date | undefined;
  setDateFrom: (date: Date) => void;
  setDateTo: (date: Date) => void;
  defaultDatePeriod?: DatePeriod;
  periods?: DatePeriod[];
  onPeriodChange?: (date: DatePeriod | string) => void;
}

const ExtendedDatePicker = ({
  dateTo,
  dateFrom,
  setDateFrom,
  setDateTo,
  periods,
  onPeriodChange,
  defaultDatePeriod,
}: Props) => {
  const { t } = useTranslation();

  const [isDatePickerFromVisible, setDatePickerFromVisible] = useState(false);
  const [isDatePickerToVisible, setDatePickerToVisible] = useState(false);
  const [datePeriod, setDatePeriod] = useState<DatePeriod | null>(
    defaultDatePeriod ? defaultDatePeriod : DatePeriod['24hours'],
  );

  useEffect(() => {
    if (datePeriod) {
      const dateRange = getDatePeriodRange(datePeriod);

      if (onPeriodChange) {
        onPeriodChange(datePeriod);
      } else {
        setDateFrom(dateRange.dateFrom);
        setDateTo(dateRange.dateTo);
      }
    }
  }, [datePeriod, setDateFrom, setDateTo, onPeriodChange]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.datePicker}>
        <DatePickerInput
          text={t('DownloadMenu.fromDate')}
          dateToDisplay={dateFrom}
          selectDate={date => {
            setDateFrom(getDayStart(date));
            setDatePeriod(null);
          }}
          dateFrom={dateFrom}
          dateTo={dateTo}
          disabledDays={dateTo && { after: dateTo }}
          inputClassName={styles.datepickerInput}
          isDatePickerVisible={isDatePickerFromVisible}
          setDatePickerVisible={setDatePickerFromVisible}
          inputIconColor="var(--secondary-color)"
        />
      </div>
      <div className={styles.datePicker}>
        <DatePickerInput
          text={t('DownloadMenu.toDate')}
          dateToDisplay={dateTo}
          selectDate={date => {
            setDateTo(getDayEnd(date));
            setDatePeriod(null);
          }}
          dateFrom={dateFrom}
          dateTo={dateTo}
          disabledDays={dateFrom && { before: dateFrom }}
          inputClassName={styles.datepickerInput}
          isDatePickerVisible={isDatePickerToVisible}
          setDatePickerVisible={setDatePickerToVisible}
          inputIconColor="var(--secondary-color)"
        />
      </div>
      <DatePeriodPicker
        datePeriod={datePeriod}
        onDatePeriodChange={setDatePeriod}
        periods={periods}
      />
    </div>
  );
};

export default ExtendedDatePicker;
