import { useEffect, useState } from 'react';
import classNames from 'classnames';

import {getChartData} from '../../shared/api/analytics';
import { ChartPoint, useChart } from '../../shared/hooks/useChart';
import { callWithErrorHandler } from '../../shared/services/ErrorHandler/callWithErrorHandler';

import { ReactComponent as ShortArrow } from '../../shared/assets/images/shortArrow.svg';
import styles from './AnomalyDetails.module.scss';

interface Props {
  sensorId: string;
}

const AnomalyDetails = ({ sensorId }: Props) => {
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();

  const [chartData, setChartData] = useState<ChartPoint[]>([]);
  const [canvasRef] = useChart([
    { data: chartData, tension: 0.05, pointRadius: 0 },
  ]);

  useEffect(() => {
    const initialDateTo = new Date();
    const initialDateFrom = new Date(initialDateTo);
    // show data for the last 3 days if data is present
    initialDateFrom.setDate(initialDateFrom.getDate() - 3);

    setDateFrom(initialDateFrom);
    setDateTo(initialDateTo);
  }, []);

  useEffect(() => {
    if (!dateFrom || !dateTo) return;

    callWithErrorHandler(async () => {
      const data = await getChartData(sensorId, dateFrom, dateTo);
      setChartData(data);
    });
  }, [sensorId, dateFrom, dateTo]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <div className={styles.header}>
          <div className={styles.title}>Tag ID ABC1234</div>
          <div className={styles.description}>Anomaly</div>
        </div>
        <div className={styles.content}>
          <div className={styles.statusDescription}>
            <div className={styles.statusCircle} />
            This issue is not critical, Optimus can still produce new
            recommendations
          </div>
          <div className={styles.mean}>
            <div className={styles.meanText}>
              Definition of anomaly, definition of mean increased. Definition of
              anomaly, definition of mean increased.
            </div>
            <div className={styles.meanInfo}>
              <div className={styles.meanStatus}>
                <span className={styles.meanIcon} />
                Mean decreased
              </div>
              <div className={styles.meanBlock}>
                <div className={styles.valueLabel}>Current value</div>
                <div className={styles.value}>20</div>
              </div>
              <div className={styles.meanBlock}>
                <div className={styles.valueLabel}>Expected value</div>
                <div className={styles.value}>128</div>
              </div>
            </div>
          </div>
          <div className={styles.detectionCount}>
            <div className={styles.count}>5</div>
            Times this issue was detected in the last three days
          </div>
          <div className={styles.moreIssues}>
            This sensor has <span className={styles.boldText}>2</span> more
            issues: low availability and another anomaly: flatline.
          </div>
          <div className={styles.contact}>
            If these issues keep appearing please contact{' '}
            <a className={styles.link} href="/">
              Sandra Cheng
            </a>
          </div>
        </div>
      </div>
      <div className={classNames(styles.card, styles.rightCard)}>
        <div className={styles.header}>
          <div className={styles.title}>Time Series</div>
          <div className={styles.description}>
            <div className={styles.datePicker}>
              3 days ago
              <div className={styles.datepickerIcon}>
                <ShortArrow className={styles.arrowIcon} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.chartWrapper}>
          <canvas className={styles.chartCanvas} ref={canvasRef} />
        </div>
      </div>
    </div>
  );
};

export default AnomalyDetails;
