import React from 'react';
import { useTranslation } from 'react-i18next';
import StatusIcon from '../StatusIcon/StatusIcon';
import styles from './Status.module.scss';

const Status = ({ status }: { status: string }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <StatusIcon status={status} />

      <div data-status-id={status || 'no-status'} className={styles.statusName}>
        {status && t(`Statuses.${status}`)}
      </div>
    </div>
  );
};

export default Status;
