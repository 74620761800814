import React, { ReactNode } from 'react';
import styles from './DropdownMenu.module.scss';

interface Props {
  open: boolean;
  children: ReactNode;
}

const DropdownMenu = ({ open, children }: Props) => {
  return open ? <div className={styles.menu}>{children}</div> : null;
};

export default DropdownMenu;
