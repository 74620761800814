const STATUSES = {
  declined: 'Declined',
  approved: 'Approved',
  pending: 'Pending',
  inreview: 'Inreview',
};

const statusColors: { [name: string]: string } = {
  [STATUSES.approved]: 'var(--approve-color)',
  [STATUSES.declined]: 'var(--decline-color)',
  [STATUSES.pending]: 'var(--pending-color)',
  [STATUSES.inreview]: 'var(--inreview-color)',
};

const getStatusColor = (statusName: string) =>
  statusColors[statusName] ? statusColors[statusName] : 'var(--gray-color)';

export default STATUSES;
export { getStatusColor, statusColors };
