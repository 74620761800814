import { Chart, ChartOptions, ChartType, ScaleType } from 'chart.js';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ThemeContext from '../themes/ThemeContext';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  getFormattedFullDateTime,
  getFormattedShortDateTime,
} from '../utils/dateHelpers';
import { formatToShortNumber } from '../utils/formatNumbers';
import { registerables } from 'chart.js';

const chartFontFamily = 'TitilliumWeb, sans-serif';

Chart.register(...registerables, zoomPlugin);

export interface ChartPoint {
  x: string | number;
  y: number;
}

export interface UseChartDataset {
  data: ChartPoint[];
  borderColor?: string;
  borderDash?: number[];
  hoverBorderColor?: string;
  pointBackgroundColor?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  borderWidth?: number;
  stepped?: boolean;
  pointRadius?: number;
  tension?: number;
  fill?: boolean | string;
}

export interface UseChartOptions {
  xAxisType?: ScaleType;
  xAxisMaxTicksLimit?: number;
  yAxisMaxTicksLimit?: number;
  xAxisTicksSource?: 'auto' | 'data' | 'labels';
  textColorVariable?: string;
  gridColorVariable?: string;
}

/*
  Custom hook for simplification work with Chart.js.

  Returns canvas reference callback that should be connected to the canvas in chart component.

  Returns function for updating chart data without creating a new chart.
  This will be useful for rendering similar charts with different data
  (e.g. choosing chart data on the dropdown and then displaying it).
*/
const defaultOptions: UseChartOptions = {
  xAxisType: 'time',
  xAxisMaxTicksLimit: 11,
  yAxisMaxTicksLimit: 8,
  xAxisTicksSource: 'auto',
  textColorVariable: '--text-color',
  gridColorVariable: '--border-color',
};

export const useChart = (
  datasets: UseChartDataset[],
  options: UseChartOptions = defaultOptions,
) => {
  const { i18n } = useTranslation();
  const { getCSSVariable } = useContext(ThemeContext);

  const [chart, setChart] = useState<Chart<ChartType, ChartPoint[]> | null>(
    null,
  );
  const canvasRef = useRef<HTMLCanvasElement>();

  const updateChart = useCallback(
    (datasets: UseChartDataset[], userOptions?: UseChartOptions) => {
      if (!chart?.ctx) return;

      if (userOptions) {
        const options = { ...defaultOptions, ...userOptions };
        options.textColorVariable =
          options.textColorVariable &&
          getCSSVariable(options.textColorVariable, canvasRef.current);
        options.gridColorVariable =
          options.gridColorVariable &&
          getCSSVariable(options.gridColorVariable, canvasRef.current);

        chart.options = getChartOptions(options, i18n.language);
      }

      chart.data.datasets = datasets.map(getDatasetConfig);
      chart.update();
    },
    [getCSSVariable, i18n.language, chart],
  );

  // recreate chart only when new context received
  const updateCanvasRef = useCallback(
    node => {
      canvasRef.current = node;
      const ctx = node?.getContext('2d');

      if (ctx && ctx !== chart?.ctx) {
        if (chart) chart.destroy();
        setChart(
          new Chart(ctx, {
            type: 'line',
            data: { datasets: [] },
            options: { maintainAspectRatio: false },
          }),
        );
      }
    },
    [chart],
  );

  useEffect(() => {
    updateChart(datasets, options);
  }, [chart, datasets, options, updateChart]);

  return [updateCanvasRef, updateChart, canvasRef] as const;
};

const getChartOptions = (
  {
    xAxisType,
    xAxisMaxTicksLimit,
    yAxisMaxTicksLimit,
    xAxisTicksSource,
    textColorVariable,
    gridColorVariable,
  }: UseChartOptions,
  locale: string,
): ChartOptions =>
  ({
    maintainAspectRatio: false,
    scales: {
      x: {
        type: xAxisType,
        time: {
          displayFormats: {
            minute: 'MMM dd, HH:mm',
            hour: 'MMM dd, HH:mm',
            day: 'MMM dd yyyy',
            month: 'MMM yyyy',
          },
        },
        beginAtZero: false,
        ticks: {
          ...(xAxisType === 'linear' && {
            maxTicksLimit: xAxisMaxTicksLimit,
          }),
          ...(xAxisType === 'time' && {
            source: xAxisTicksSource,
            callback: date => getFormattedShortDateTime(new Date(date), locale),
          }),
          padding: 10,
          maxRotation: 0,
          minRotation: 0,
          color: textColorVariable,
          font: {
            family: chartFontFamily,
          },
          autoSkipPadding: 10,
        },
        grid: {
          drawOnChartArea: false,
          color: gridColorVariable,
          borderColor: gridColorVariable,
        },
      },
      y: {
        type: 'linear',
        beginAtZero: false,
        ticks: {
          maxTicksLimit: yAxisMaxTicksLimit,
          padding: 10,
          color: textColorVariable,
          font: {
            family: chartFontFamily,
          },
          callback: value => formatToShortNumber(Number(value)),
        },
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          color: gridColorVariable,
          borderColor: gridColorVariable,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: ([{ parsed }]) =>
            getFormattedFullDateTime(new Date(parsed.x), locale),
          label: ({ parsed }) => ` ${formatToShortNumber(Number(parsed.y))}`,
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
      legend: { display: false },
    },
  } as ChartOptions<ChartType>);

const getDatasetConfig = (dataset: UseChartDataset) => ({
  borderWidth: 1,
  borderColor: 'white',
  pointBackgroundColor: 'white',
  fill: false,
  stepped: false,
  pointRadius: 3,
  tension: 0,
  ...dataset,
});
