import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import PopupIconButton, {
  preventPopupClosingOnClick,
} from '../IconButton/PopupIconButton';
import Switch from '../Switch/Switch';
import Languages from './Languages';

import { AppContext } from '../../shared/AppContext';
import { ReactComponent as SettingsIcon } from '../../shared/assets/images/settingsIcon.svg';

import styles from './Settings.module.scss';

const Settings = () => {
  const { showTooltipMarkers, setShowTooltipMarkers } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <PopupIconButton
        xAlign="left"
        yAlign="top"
        icon={
          <span className={styles.icon}>
            <SettingsIcon />
          </span>
        }
      >
        <div className={styles.content} onClick={preventPopupClosingOnClick}>
          <Languages />

          <Switch
            checked={showTooltipMarkers}
            onChange={() => setShowTooltipMarkers(!showTooltipMarkers)}
            label={<div className={styles.title}>{t('Settings.tooltips')}</div>}
          />
        </div>
      </PopupIconButton>
    </div>
  );
};

export default Settings;
