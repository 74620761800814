import classNames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';
import styles from './Card.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
  style?: CSSProperties;
  className?: string;
}

const Card = ({ children, style, className }: Props) => {
  return (
    <div className={classNames(styles.card, className)} style={style}>
      {children}
    </div>
  );
};

export default Card;
