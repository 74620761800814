import {
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { getAuthConfiguration } from '../api/authConfig';

let msalInstance: any = null;

const AppAD = (): IPublicClientApplication => {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(getAuthConfiguration());
  }

  return msalInstance;
};

export default AppAD;
