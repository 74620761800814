import filter from 'lodash/filter';
import React, { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import ContentPlaceholder from '../../components/ContentPlaceholder/ContentPlaceholder';
import ExtendedDatePicker from '../../components/ExtendedDatePicker/ExtendedDatePicker';
import Subheader from '../../components/Subheader/Subheader';
import { DatePeriod } from '../../shared/utils/getDatePeriodRange';
import styles from './Database.module.scss';
import DatabaseSection from './DatabaseSection';
import DataTable from './DataTable/DataTable';
import {
  getTablesInfo,
  getCurrentRun,
} from '../../shared/api/database';


const NO_COUNT_PLACEHOLDER = '-';



const Database = () => {
  const [dateFrom, setDateFrom] = useState<Date>(new Date(0));
  const [dateTo, setDateTo] = useState<Date>(new Date());
  const [isDataFetched, setFetchData] = useState(false);

  const { t } = useTranslation();

  const [dbStats, setDbStats] = useState<{ [key: string]: any }>({});
  const [tablesStats, setTablesStats] = useState<{
    [key: string]: TableInfoData;
  }>({});

  const onDatePeriodChange = useCallback(
    async (datePeriod: DatePeriod | string) => {
      if (datePeriod === DatePeriod.currentRun) {
        const response = await getCurrentRun();

        unstable_batchedUpdates(() => {
          setDateFrom(new Date(response.data.timestamp));
          setDateTo(new Date(response.data.timestamp));
        });
      }

      if (datePeriod === DatePeriod.all) {
        setDateFrom(new Date(0));
        setDateTo(new Date());
      }
    },
    [],
  );

  useEffect(() => {
    (async () => {
      setFetchData(false);
      const response = await getTablesInfo(dateFrom, dateTo);
      setDbStats(response.data.db_stats);
      setTablesStats(response.data.tables_stats);
      setFetchData(true);
    })();
  }, [dateTo, dateFrom]);

  const tablesWithTimestamp = filter(tablesStats, el => !el.without_timestamp);
  const tablesWithoutTimestamp = filter(
    tablesStats,
    el => !!el.without_timestamp,
  );

  return (
    <div className={styles.wrapper}>
      <Subheader
        title={t('Database.header')}
        right={
          <ExtendedDatePicker
            dateTo={dateTo}
            dateFrom={dateFrom}
            setDateTo={setDateTo}
            setDateFrom={setDateFrom}
            periods={[DatePeriod.all, DatePeriod.currentRun]}
            onPeriodChange={onDatePeriodChange}
            defaultDatePeriod={DatePeriod.all}
          />
        }
      />

      <Routes>
        <Route
          index
          element={
            <>
              <div className={styles.section}>
                <div className={styles.dbStats}>
                  <div className={styles.subTitle}>
                    <span className={styles.number}>
                      {dbStats.tables_count || NO_COUNT_PLACEHOLDER}
                    </span>{' '}
                    tables
                  </div>
                  <div className={styles.subTitle}>
                    <span className={styles.number}>
                      {dbStats.db_entities || NO_COUNT_PLACEHOLDER}
                    </span>{' '}
                    records
                  </div>
                  <div className={styles.subTitle}>
                    <span className={styles.number}>
                      {dbStats.db_size || NO_COUNT_PLACEHOLDER}
                    </span>{' '}
                    GB data
                  </div>
                </div>
              </div>
              <ContentPlaceholder isLoading={!isDataFetched} height={300}>
                <div className={styles.tablesStats}>
                  {tablesWithTimestamp.map(({ id, count }) => (
                    <DatabaseSection
                      key={id + count}
                      tableName={id}
                      count={count}
                    />
                  ))}
                </div>
                <div className={styles.separator} />
                <div className={styles.tablesStats}>
                  {tablesWithoutTimestamp.map(({ id, count }) => (
                    <DatabaseSection
                      key={id + count}
                      tableName={id}
                      count={count}
                    />
                  ))}
                </div>
              </ContentPlaceholder>
            </>
          }
        />

        <Route
          path="/:table"
          element={
            <DataTable
              dateTo={dateTo}
              dateFrom={dateFrom}
              tablesStats={tablesStats}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default Database;
