import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectiveFunction, Target } from '../../shared/types/target';
import { DatePeriod } from '../../shared/utils/getDatePeriodRange';
import styles from './HistoricalMetrics.module.scss';
import { MetricsRow } from './MetricsRow/MetricsRow';
import { ReactComponent as DownloadIcon } from '../../shared/assets/images/downloadIcon.svg';
import { downloadFile } from '../../shared/utils/downloadFile';
import { callWithErrorHandler } from '../../shared/services/ErrorHandler/callWithErrorHandler';
import analyticsApi from '../../shared/api/appApi';
import {
  getDayEnd,
  getDayStart,
  getISODateWithoutMilliseconds,
} from '../../shared/utils/dateHelpers';

const HistoricalMetrics = ({
  target,
  dateFrom,
  dateTo,
}: {
  target: Target;
  dateFrom?: Date;
  dateTo?: Date;
}) => {
  const { t } = useTranslation();

  const downloadTargetsData = async () => {
    if (!dateFrom || !dateTo) return;

    callWithErrorHandler(async () => {
      const response = await analyticsApi.get(
        `/impact_tracking/${target.id}/download`,
        {
          responseType: 'arraybuffer',
          params: {
            date_from: getISODateWithoutMilliseconds(getDayStart(dateFrom)),
            date_to: getISODateWithoutMilliseconds(getDayEnd(dateTo)),
          },
        },
      );

      downloadFile(response);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {t('HistoricalMetrics.title')}
        <div className={styles.downloadIcon}>
          <DownloadIcon onClick={downloadTargetsData} />
        </div>
      </div>

      <div className={styles.subtitle}>
        <span className={styles.aggregation}>
          {t(`HistoricalMetrics.${target.aggregation}`)}
        </span>

        <span>{t(`HistoricalMetrics.${target.objective}`)}</span>
      </div>

      <div className={styles.metrics}>
        <div className={classNames(styles.gridRow, styles.metricNames)}>
          <div className={styles.metricName}>
            {t('HistoricalMetrics.actual')}
          </div>

          <div className={styles.metricName}>
            {target.objective === ObjectiveFunction.max &&
              t('HistoricalMetrics.uplift')}

            {target.objective === ObjectiveFunction.min &&
              t('HistoricalMetrics.reduction')}
          </div>
        </div>

        {dateFrom && dateTo && (
          <MetricsRow
            target={target}
            dateFrom={dateFrom}
            dateTo={dateTo}
            containerClassName={styles.gridRow}
          />
        )}

        {Object.values(DatePeriod).map(period => (
          <MetricsRow
            key={`metric-row-${period}`}
            target={target}
            period={period}
            containerClassName={styles.gridRow}
          />
        ))}
      </div>
    </div>
  );
};

export default HistoricalMetrics;
