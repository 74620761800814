export enum ObjectiveFunction {
  min = 'min',
  max = 'max',
}

export interface Target {
  id: string;
  name: string;
  unit: string;
  aggregation: string;
  objective: ObjectiveFunction;
}
