import React from 'react';
import classNames from 'classnames';
import styles from './StatusIndicator.module.scss';
import { Cell } from 'react-table';

const statusColor: any = {
  Good: 'good',
  Warning: 'warning',
  Bad: 'danger',
};

const StatusIndicator = ({ cell }: { cell: Cell }) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(
          styles.statusCircle,
          styles[statusColor[cell.value] || 'unknown'],
        )}
      />
    </div>
  );
};

export default StatusIndicator;
