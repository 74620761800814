import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectiveFunction, Target } from '../../../shared/types/target';
import { getFormattedDate } from '../../../shared/utils/dateHelpers';
import { formatToShortNumber } from '../../../shared/utils/formatNumbers';
import { DatePeriod } from '../../../shared/utils/getDatePeriodRange';
import styles from './MetricsRow.module.scss';
import { useHistoricalMetrics } from './useHistoricalMetrics';

interface Props {
  target: Target;
  containerClassName?: string;
}

interface PeriodProps extends Props {
  period: DatePeriod;
  dateFrom?: undefined;
  dateTo?: undefined;
}

interface DateRangeProps extends Props {
  period?: undefined;
  dateFrom: Date;
  dateTo: Date;
}

export const MetricsRow = ({
  containerClassName,
  ...props
}: PeriodProps | DateRangeProps) => {
  const { actual, difference } = useHistoricalMetrics(props);
  const { t, i18n } = useTranslation();
  const [isDifferencePositive, setDifferencePositive] = useState<boolean>();

  useEffect(() => {
    if (!difference) return;

    if (props.target.objective === ObjectiveFunction.min) {
      setDifferencePositive(difference < 0);
    } else {
      setDifferencePositive(difference > 0);
    }
  }, [props.target, difference]);

  if (!actual || !difference) return null;

  return (
    <div className={containerClassName}>
      <div className={styles.metricDateRange}>
        {props.dateFrom &&
          props.dateTo &&
          `${getFormattedDate(
            props.dateFrom,
            i18n.language,
          )} - ${getFormattedDate(props.dateTo, i18n.language)}`}

        {props.period && t(`HistoricalMetrics.${props.period}`)}
      </div>

      <div className={styles.valueContainer}>
        <div className={styles.actual}>{formatToShortNumber(actual)}</div>
      </div>

      <div className={styles.valueContainer}>
        <div
          className={classNames(
            styles.difference,
            isDifferencePositive
              ? styles.differencePositive
              : styles.differenceNegative,
          )}
        >
          {isDifferencePositive &&
            props.target.objective === ObjectiveFunction.max &&
            '+'}

          {formatToShortNumber(difference)}
        </div>
      </div>
    </div>
  );
};
