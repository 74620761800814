import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';

const POINTS_NUMBER_TO_RENDER = 50;
const HOURS_PER_DAY = 24;
const DAYS_PER_MONTH = 30;
const DAYS_PER_YEAR = 365;
const MS_PER_HOUR = 1000 * 60 * 60;
const MS_PER_DAY = MS_PER_HOUR * HOURS_PER_DAY;

export enum Period {
  Hour = 0,
  Day = 1,
  Month = 2,
  Year = 3,
}

interface DataPoint {
  timestamp: string;
}

export function sampleTimeSeriesData<T extends DataPoint>(data: T[]) {
  if (data.length <= POINTS_NUMBER_TO_RENDER) return data;

  const dateRangeMs =
    new Date(data[data.length - 1].timestamp).getTime() -
    new Date(data[0].timestamp).getTime();
  const groupPeriod = getGroupPeriod(dateRangeMs);

  return samplePoints<T>(data, groupPeriod);
}

function getGroupPeriod(dateRangeMs: number) {
  const dateRangeHours = dateRangeMs / MS_PER_HOUR;
  const dateRangeDays = dateRangeMs / MS_PER_DAY;

  if (dateRangeHours <= HOURS_PER_DAY) return Period.Hour;

  if (dateRangeDays <= DAYS_PER_MONTH) return Period.Day;

  if (dateRangeDays > DAYS_PER_YEAR) return Period.Year;

  return Period.Month;
}

function samplePoints<T extends DataPoint>(data: T[], period: Period) {
  const dataByPeriod = groupBy(data, groupFunction[period]);

  // get the percentage relation of points for the period to the total number of points
  const pointsPerPeriodPercentage = mapValues(
    dataByPeriod,
    points => (points.length * 100) / data.length,
  );

  const sampled = [];

  for (const [key, points] of Object.entries(dataByPeriod)) {
    // get a number of new points keeping percentage relation for the period
    const newPointsNumber = Math.round(
      (POINTS_NUMBER_TO_RENDER * pointsPerPeriodPercentage[key]) / 100,
    );

    // split points to a number of parts equal to newPointsNumber
    // then get the last value from each part
    const newPoints = splitToChunks<T>(points, newPointsNumber).map(
      pointsArray => pointsArray.slice(-1)[0],
    );

    sampled.push(...newPoints);
  }

  return sortBy(sampled, point => point.timestamp);
}

const groupFunction = {
  [Period.Hour]: (point: DataPoint) => new Date(point.timestamp).getHours(),
  [Period.Day]: (point: DataPoint) => new Date(point.timestamp).getDate(),
  [Period.Month]: (point: DataPoint) => new Date(point.timestamp).getMonth(),
  [Period.Year]: (point: DataPoint) => new Date(point.timestamp).getFullYear(),
};

function splitToChunks<T extends DataPoint>(array: T[], parts: number) {
  const arrayCopy = [...array];
  const result = [];

  for (let i = parts; i > 0; i--) {
    result.push(arrayCopy.splice(0, Math.ceil(arrayCopy.length / i)));
  }

  return result;
}
