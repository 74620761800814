import { SankeyChartData } from './SankeyChartTypes';
import { generatePoints } from './sankeyChartGenerator';
import { transition, Transition } from 'd3-transition';
import { select } from 'd3-selection';
import { curveCatmullRom, line } from 'd3-shape';
import styles from './SankeyChart.module.scss';

/*
  animation have three stages:

  * removing paths that doesnt exist anymore
  * adding new paths and updating old
  * animating gradient
  this is timings for this actions:
*/
const timings = [
  // removing useless paths
  200,
  // adding new, updating old paths
  700,
  // animate gradient on new paths
  1000,
];

const initialize = (id: string) => {
  const svg = select('#' + id);

  svg.append('defs');

  svg
    .append('rect')
    .attr('fill', 'var(--background-color)')
    .attr('stroke', 'none');

  svg
    .append('text')
    .attr('fill', 'var(--white-color)')
    .attr('class', styles.text);
};

const update = (
  data: Array<SankeyChartData>,
  size: { x: number; y: number },
  showTooltip: (event: unknown, item: SankeyChartData) => void,
  hideTooltip: () => void,
  id: string,
  text: string,
  options: any,
) => {
  const updateTransition: Transition<any, any, any, any> = transition()
    .delay(timings[0])
    .duration(timings[1] - timings[0]);

  const svg = select('#' + id);
  const defs = svg.select('defs');

  svg.select('text').text(text);

  const textNode: any = svg.select('text').node();
  const textOffset = textNode.getComputedTextLength() + 35;

  const { textBlockHeight, charts, startPoint } = generatePoints(
    data,
    size,
    textOffset,
    options,
  );

  charts.forEach(item => {
    if (svg.select(`#gradient-${item.key}-${id}`).empty()) {
      const lg = defs
        .append('linearGradient')
        .attr('id', `gradient-${item.key}-${id}`)
        .attr('gradientTransform', 'rotate(0)');

      lg.append('stop')
        .attr('offset', '0%')
        .attr('stop-opacity', 0)
        .attr('stop-color', 'var(--background-color)');

      lg.append('stop')
        .attr('offset', '0%')
        .attr('stop-opacity', 1)
        .attr('stop-color', item.color);

      lg.append('stop')
        .attr('offset', '0%')
        .attr('stop-opacity', 1)
        .attr('stop-color', item.color);

      lg.append('stop').attr('offset', '0%').attr('stop-opacity', 0);
    }
  });

  const paths = svg.selectAll('path').data(charts, (d: any) => d.key);

  // when path removed
  paths
    .exit()
    .on('mouseenter', null)
    .on('mouseleave', null)
    .each((d: any) => {
      const gradient = select(`#gradient-${d.key}-${id}`);

      gradient
        .selectAll('stop')
        .transition()
        .attr('offset', '0%')
        .duration(timings[0]);

      gradient
        .select('stop:nth-child(1)')
        .transition()
        .delay(timings[0])
        .attr('stop-opacity', 0)
        .duration(0);
    })
    .transition()
    .duration(timings[0])
    .remove();

  // path created
  paths
    .enter()
    .append('path')
    .attr('class', d => styles[d.key])
    .attr('id', d => `sankey-path-${d.key}-${id}`)
    .attr('fill', d => `url(#gradient-${d.key}-${id})`)
    .attr('d', d => line().curve(curveCatmullRom.alpha(0.95))(d.points) || '')
    .attr('stroke-width', 0)
    .each(d => {
      const gradient = select(`#gradient-${d.key}-${id}`);

      gradient
        .select('stop:nth-child(1)')
        .transition()
        .delay(timings[1])
        .attr('stop-opacity', 1)
        .duration(0);

      gradient
        .selectAll('stop')
        .data(['0%', '9%', '99%', '99%'])
        .transition()
        .delay(timings[1])
        .attr('offset', d => d)
        .duration(timings[2] - timings[1]);
    })
    .on('mouseenter', showTooltip)
    .on('mouseleave', hideTooltip);

  // path update (also called on path created)
  svg
    .selectAll('path')
    .data(charts, (d: any) => d.name)
    .transition(updateTransition)
    .attr('d', d => line().curve(curveCatmullRom.alpha(0.95))(d.points) || '');

  svg
    .select('rect')
    .transition(updateTransition)
    .attr('y', startPoint.y)
    .attr('width', startPoint.x + 1)
    .attr('height', textBlockHeight);

  svg
    .select('text')
    .transition(updateTransition)
    .attr('y', startPoint.y + 12 + (textBlockHeight - 18) / 2)
    .attr('x', 12);
};

const D3Chart = {
  initialize,
  update,
};

export default D3Chart;
