import React, { useEffect, ReactNode } from 'react';
import {
  useResizeColumns,
  useTable,
  useSortBy,
  useRowSelect,
  useFilters,
  useFlexLayout,
  useExpanded,
  Column,
  TableInstance,
  Row,
  usePagination,
  SortingRule,
} from 'react-table';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import keys from 'lodash/keys';
import HeaderGroup from './Header/RecommendationTableHeaderGroup';
import styles from './Table.module.scss';
import TableRow from './Row/TableRow';
import Pagination from './Pagination/Pagination';
import ContentPlaceholder from '../ContentPlaceholder/ContentPlaceholder';
import { useTranslation } from 'react-i18next';

interface Filters {
  [key: string]: {
    [key: string]: boolean;
  };
}

interface Props {
  data: Array<{
    [name: string]: any;
  }>;
  initialSortBy?: Array<SortingRule<any>>;
  filters?: Filters;
  tableStyles?: string;
  rowClassName?: string;
  getRowStyles?: (row: Row<any>) => { [name: string]: string };
  columns: Column<any>[];
  /* the React component that will be rendered after expanding row */
  expandContent?: (row: Row<any>) => ReactNode;
  /* some extra component that will be rendered after table */
  extraContent?: (props: TableInstance) => ReactNode;
  pagination?: boolean;
  isDataFetched?: boolean;
}

const getRowId = (row: any) => row.id;

const Table = ({
  data,
  initialSortBy = [],
  isDataFetched = true,
  filters = {},
  columns,
  pagination = false,
  expandContent,
  extraContent,
  tableStyles,
  rowClassName,
  getRowStyles,
}: Props) => {
  const props = useTable(
    {
      autoResetSortBy: false,
      autoResetExpanded: false,
      getRowId,
      columns,
      pageSize: 20,
      data,
      initialState: {
        sortBy: initialSortBy,
      },
    },
    useResizeColumns,
    useFlexLayout,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    setFilter,
    toggleRowExpanded,
    state: { expanded, pageIndex },
  } = props;

  const { t } = useTranslation();

  // collapse old expanded rows if some new row was expanded recently
  useEffect(() => {
    const ids: string[] = Object.keys(expanded);
    toggleRowExpanded(ids.slice(0, ids.length - 1), false);
  }, [expanded, toggleRowExpanded]);

  useEffect(() => {
    Object.entries(filters).forEach(([name, filters]) => {
      setFilter(name, keys(pickBy(filters)));
    });
  }, [data, filters, setFilter]);

  return (
    <div className={styles.wrapper}>
      {extraContent && extraContent(props)}

      <div
        {...getTableProps()}
        className={classNames(styles.table, tableStyles)}
      >
        {headerGroups.map((headerGroup: any) => (
          <div {...headerGroup.getHeaderGroupProps()} className={styles.header}>
            {headerGroup.headers.map((column: any) => (
              <HeaderGroup column={column} {...column.getHeaderProps()} />
            ))}
          </div>
        ))}

        <ContentPlaceholder
          isLoading={!isDataFetched}
          doesContentExist={pagination ? page.length > 0 : rows.length > 0}
          noContentTitle={t('Table.noRecords')}
          height={300}
        >
          <div {...getTableBodyProps()}>
            {(pagination ? page : rows).map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  row={row}
                  expandedRowBody={expandContent || undefined}
                  rowClassName={rowClassName}
                  getRowStyles={getRowStyles}
                />
              );
            })}
          </div>
        </ContentPlaceholder>

        {pagination && (
          <Pagination
            currentPage={pageIndex}
            pageSize={20}
            numberOfEntities={pageCount * 20}
            goToPage={gotoPage}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
