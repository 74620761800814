import React, { ReactNode } from 'react';
import { getFormattedShortDateTime } from '../../shared/utils/dateHelpers';
import styles from './TableSummary.module.scss';
import { useTranslation } from 'react-i18next';
import Subheader from '../Subheader/Subheader';

interface Props {
  lastUpdated: string | undefined;
  extraContent?: ReactNode;
  title: string;
  count: number;
}

const TableSummary = ({ lastUpdated, title, count, extraContent }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <Subheader
      title={
        <>
          <span className={styles.countInTitle}>{count}</span>
          {title}
          {lastUpdated && (
            <>
              , {t('Table.lastUpdate')}{' '}
              {getFormattedShortDateTime(new Date(lastUpdated), i18n.language)}
            </>
          )}
        </>
      }
      right={extraContent}
    />
  );
};

export default TableSummary;
