import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SortingRule } from 'react-table';
import Pagination from '../../../components/Table/Pagination/Pagination';
import Table from '../../../components/Table/Table';
import analyticsApi from '../../../shared/api/appApi';
import { getISODateWithoutMilliseconds } from '../../../shared/utils/dateHelpers';
import styles from './DataTables.module.scss';

const PAGE_SIZE = 10;
const DEFAULT_PAGE = 0;

interface Props {
  dateTo: Date | undefined;
  dateFrom: Date | undefined;
  tablesStats: { [key: string]: TableInfoData };
}

const getTableData = (
  tableName: string,
  page: number,
  page_size: number,
  dateFrom: Date,
  dateTo: Date,
) =>
  analyticsApi.get(`/data_resources/${tableName}`, {
    params: {
      records_count: page_size,
      records_offset: page * page_size,
      date_from: getISODateWithoutMilliseconds(dateFrom),
      date_to: getISODateWithoutMilliseconds(dateTo),
    },
  });

const getColumns = (data: Array<any>, disabledColumns: Array<string>) => {
  if (data.length <= 0) return [];

  const columnNames = Object.keys(data[0]).filter(
    key => !disabledColumns.includes(key),
  );

  return columnNames.map((columnName: string) => ({
    Header: columnName,
    accessor: columnName,
    disableSortBy: true,
  }));
};

const DataTable = ({ dateTo, dateFrom, tablesStats }: Props) => {
  const { table = '' } = useParams<{ table: string }>();

  const [data, setData] = useState<Array<{ [key: string]: any }>>([]);
  const [sortColumn, setSortColumn] = useState<Array<SortingRule<any>>>([]);
  const [disabledColumns, setDisabledColumns] = useState<Array<string>>([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);

  const totalNumberOfRecords = tablesStats[table]?.count || 0;
  const tableColumns = getColumns(data, disabledColumns);

  useEffect(() => {
    (async () => {
      if (dateTo && dateFrom) {
        const response = await getTableData(
          table,
          currentPage,
          PAGE_SIZE,
          dateFrom,
          dateTo,
        );
        setData(response.data.records);
        setSortColumn([{ id: response.data.sort_column, desc: false }]);
        setDisabledColumns(response.data.hidden_columns);
      }
    })();
  }, [currentPage, dateTo, dateFrom, table]);

  return (
    <div className={styles.section}>
      <Table
        columns={tableColumns}
        data={data}
        rowClassName={styles.tableRow}
        initialSortBy={sortColumn}
      />
      <Pagination
        numberOfEntities={totalNumberOfRecords}
        currentPage={currentPage}
        goToPage={(newPage: number) => setCurrentPage(newPage)}
        pageSize={PAGE_SIZE}
      />
    </div>
  );
};

export default DataTable;
