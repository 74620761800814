import React, { useState } from 'react';
import { Row } from 'react-table';
import analyticsApi from '../../shared/api/appApi';
import { useFetchData } from '../../shared/hooks/useFetchData';
import { includesFilter } from '../../shared/utils/tableHelper';
import AnomalyDetails from '../AnomalyDetails/AnomalyDetails';
import DownloadMenu from '../DownloadMenu/DownloadMenu';
import Table from '../Table/Table';
import { StatusIndicator, ValueField } from '../TableCells';
import TableFilters from '../TableFilters/TableFilters';
import TableSummary from '../TableSummary/TableSummary';
import styles from './AnomaliesTable.module.scss';
import { Anomaly } from './types';
import useAnomaliesFilters from './useAnomalyFilters';

const getAnomalies = async () => analyticsApi.get('/anomalies');

const AnomaliesTable = () => {
  const [anomalies, , isDataFetched] = useFetchData<Anomaly[]>(
    getAnomalies,
    [],
  );
  const [updateTime] = useState({ updated_at: '2020-08-17T10:20:06.833Z' });

  const { filters, filterOptions, updateFilters } =
    useAnomaliesFilters(anomalies);

  /*
    Cell and Header props - specifies Component that will be used to render this cells
    Check imports to find out where this components stored
   */

  const columns = [
    {
      Header: 'Criticality',
      Cell: StatusIndicator,
      accessor: 'criticality',
      titleAlign: 'center',
      filter: includesFilter,
      width: 60,
    },
    {
      Header: 'Sensor',
      accessor: 'sensor',
    },
    {
      Header: 'Data Source',
      Cell: () => <span>Data source 1</span>,
      filter: includesFilter,
    },
    {
      Header: 'Tag ID',
      accessor: 'sensor_id',
    },
    {
      Header: 'Issue',
      accessor: 'description',
    },
    {
      Header: 'Value',
      Cell: ValueField,
      titleAlign: 'center',
      accessor: 'value',
    },
  ];

  return (
    <>
      <TableSummary
        title={'new issues'}
        count={anomalies.length}
        lastUpdated={updateTime?.updated_at}
        extraContent={
          <>
            {filterOptions && (
              <TableFilters
                updateFilters={updateFilters}
                filters={filters}
                filtersOptions={filterOptions}
              />
            )}

            <DownloadMenu />
          </>
        }
      />

      <Table
        data={anomalies}
        columns={columns}
        filters={filters}
        isDataFetched={isDataFetched}
        expandContent={(row: Row<Anomaly>) => (
          <AnomalyDetails sensorId={row.original.sensor_id} />
        )}
        tableStyles={styles.tableStyles}
      />
    </>
  );
};

export default AnomaliesTable;
