import axios from 'axios';

import { getEnvironmentVariables } from '../environment';

export function getApiUrl(): string {
  const { apiURL, apimURL, enableAPIManagement } = getEnvironmentVariables();
  const apiVersion = 'v1';

  if (apiURL === undefined) {
    throw new Error(
      'You have not provided REACT_APP_OPTIMUS_ANALYTICS_API environment variable',
    );
  }

  if (enableAPIManagement && apimURL === undefined) {
    throw new Error(
      'You have not provided REACT_APP_API_MANAGER_URL environment variable',
    );
  }

  const url = enableAPIManagement
    ? `${apimURL}/api/${apiVersion}`
    : `${apiURL}/api/${apiVersion}`;
  return url;
}

const Api = axios.create({
  baseURL: getApiUrl(),
});

export default Api;
