const getOS = () => {
  if (navigator.appVersion.indexOf('Win') !== -1) return 'Windows';
  if (navigator.appVersion.indexOf('Mac') !== -1) return 'MacOS';
  if (navigator.appVersion.indexOf('X11') !== -1) return 'UNIX';
  if (navigator.appVersion.indexOf('Linux') !== -1) return 'Linux';

  return 'Unknown OS';
};

const getRandomKey = () => {
  return `f${(~~(Math.random() * 1e8)).toString(16)}`;
};

function generateClientId() {
  return `${getRandomKey()}_${getOS()}`;
}

export default generateClientId;
