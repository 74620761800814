import { Route, Routes } from 'react-router-dom';

import SideBar from '../../components/SideBar/SideBar';
import TopBar from '../TopBar/TopBar';
import ServerEvents from '../../custom_components/ServerEvents/ServerEvents';

import Analytics from '../../pages/Analytics/Analytics';
import Anomalies from '../../pages/Anomalies/Anomalies';
import Database from '../../pages/Database/Database';

import ImpactTracking from '../../pages/ImpactTracking/ImpactTracking';
import OpenApiDocumentation from '../../pages/OpenApiDocumentation/OpenApiDocumentation';
import Performance from '../../pages/Performance/Performance';
import ReportingPage from '../../pages/Reporting/ReportingPage';
import Auth2 from '../Auth2/Auth2';

import { getEnvironmentVariables } from '../../shared/environment';
import { hideElements } from '../../shared/common';

import appConfig from '../../application.json';
import { ROUTES } from '../../routes';

const Main = () => {
  const { enableServerEvents, enableADIntegration } = getEnvironmentVariables();

  return (
    <>
      {!enableADIntegration && <Auth2 />}
      {enableServerEvents && <ServerEvents />}
      <SideBar />

      <main>
        {/* <div>{!token && <p>Loading...</p>}</div> */}
        <TopBar />
        <Routes>
          {appConfig?.pages?.analytics && (
            <Route path={ROUTES.initial} element={<Analytics />} />
          )}

          {appConfig?.pages?.anomalies && (
            <Route path={ROUTES.anomalies} element={<Anomalies />} />
          )}

          {appConfig?.pages?.impact_tracking && (
            <Route path={ROUTES.impactTracking} element={<ImpactTracking />} />
          )}

          {appConfig?.pages?.performance && (
            <Route path={ROUTES.performance} element={<Performance />} />
          )}

          {appConfig?.pages?.reporting && (
            <Route path={ROUTES.reporting} element={<ReportingPage />} />
          )}

          {appConfig?.pages?.database && (
            <Route path={ROUTES.database + '/*'} element={<Database />} />
          )}

          {appConfig?.pages?.open_api_docs && (
            <Route
              path={ROUTES.openApiDocumentation}
              element={<OpenApiDocumentation />}
            />
          )}

          <Route path="*" element={<Analytics />} />
        </Routes>
        <p>v.0.0.3</p>
      </main>
    </>
  );
};

export default Main;
