import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import HorizontalBarChart from '../../HorizontalBarChart/HorizontalBarChart';
import { getStatusColor } from '../../../shared/constants/statuses';
import { useTargetColors } from '../../../shared/hooks/useTargetColors';
import { Recommendation } from '../../RecommendationTable/types';

import groupBy from 'lodash/groupBy';
import countBy from 'lodash/countBy';
import orderBy from 'lodash/orderBy';

import { ReactComponent as ShortArrow } from '../../../shared/assets/images/shortArrow.svg';
import styles from './PerformanceFilters.module.scss';

interface Props {
  setFilter: (filterGroup: string, filter: string) => void;
  currentFilter: { group: string; value: string };
  recommendations: Recommendation[];
}

interface FiltersGroupProps {
  title?: string;
  filterKey: string;
  setFilter: (filterGroup: string, filter: string) => void;
  currentFilter: { group: string; value: string };
  items: Array<{
    title: string;
    key: string;
    statuses: { [name: string]: number };
  }>;
}

const FiltersGroup = ({
  title,
  items,
  currentFilter,
  setFilter,
  filterKey,
}: FiltersGroupProps) => (
  <div className={styles.filterGroup}>
    {title && <div className={styles.filterGroupName}>{title}</div>}
    {items.map(({ title, key, statuses }) => {
      const data = orderBy(Object.entries(statuses), [0], ['asc']).map(
        ([name, count]) => ({
          color: getStatusColor(name),
          value: count,
        }),
      );

      const isActive =
        currentFilter.group === filterKey && key === currentFilter.value;

      return (
        <div
          onClick={() => setFilter(filterKey, key)}
          className={classNames(styles.filter, isActive && styles.activeFilter)}
          key={title}
        >
          <div className={styles.filterName}>{title}</div>
          <div className={styles.rightPart}>
            <div className={styles.statusBarChart}>
              <HorizontalBarChart data={data} />
            </div>
            {!isActive && (
              <div className={styles.selectFilterIcon}>
                <ShortArrow className={styles.arrowIcon} />
              </div>
            )}
          </div>
        </div>
      );
    })}
  </div>
);

const PerformanceFilters = ({
  recommendations,
  currentFilter,
  setFilter,
}: Props) => {
  const targetColors = useTargetColors();
  const { t } = useTranslation();

  const targets = orderBy(
    Object.entries(groupBy(recommendations, 'target_id')),
    [0],
    ['asc'],
  );
  const targetFilters = targets.map(([key, recs]) => ({
    title: targetColors[key] ? targetColors[key].name : key,
    key,
    statuses: countBy(recs, 'status'),
  }));

  const areas = orderBy(
    Object.entries(groupBy(recommendations, 'area')),
    [0],
    ['asc'],
  );
  const areaFilters = areas.map(([key, recs]) => ({
    title: key,
    key,
    statuses: countBy(recs, 'status'),
  }));

  const allFilter = [
    {
      title: t('Performance.all'),
      key: '',
      statuses: countBy(recommendations, 'status'),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <FiltersGroup
        filterKey=""
        items={allFilter}
        currentFilter={currentFilter}
        setFilter={setFilter}
      />
      <FiltersGroup
        title={t('Performance.byArea')}
        filterKey="area"
        items={areaFilters}
        currentFilter={currentFilter}
        setFilter={setFilter}
      />
      <FiltersGroup
        title={t('Performance.byTarget')}
        filterKey="target_id"
        items={targetFilters}
        currentFilter={currentFilter}
        setFilter={setFilter}
      />
    </div>
  );
};

export default PerformanceFilters;
