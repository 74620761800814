import Api from './shared';
import tokenInterceptor from './tokenInterceptor';

const appApi = Api;

appApi.interceptors.request.use(tokenInterceptor);

// This is an example that may need to change for a specific sso solution
// * this assume a json object returned that looks like this {"token": "...."}
// * also you will need to change the url
const getAuthToken = async () => {
  const { data } = await appApi.get('/auth/token');

  if (data.token) {
    localStorage.setItem('token', data.token);
  }
};

export default appApi;
export { getAuthToken };
