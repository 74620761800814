import { SankeyChartData } from './SankeyChartTypes';

const minimumPercentage = 0.13;
const defaultRowPadding = 14;
const smallRowsBecomeBigRange = { start: 5, end: 35 };
const rangeModifiersForCenterRow = { start: 10, end: 5 };

interface GraphStage {
  height: number;
  padding: number;
  contentHeight: number;
}

const removeEmptyFields = (data: Array<SankeyChartData>) =>
  data.filter(value => value.count > 0);

const setDefaultValue = (data: Array<SankeyChartData>) =>
  !data[0]
    ? [{ key: 'none', name: 'new', count: 1, points: [], color: '#0c2532' }]
    : data;

const setMinimalValues = (data: Array<SankeyChartData>, minValue: number) => {
  const typesCount = data.length;
  const itemsCount = data.reduce((a, b) => a + b.count, 0);
  const min = minValue * typesCount > 1 ? 1 / typesCount : minValue;

  let reserved = { count: itemsCount, percentage: 1 };

  const setMinPercentage = (changed = false) => {
    data.forEach(item => {
      if (!item.percentage) {
        if ((item.count / reserved.count) * reserved.percentage < min) {
          item.percentage = min;
          reserved = {
            count: reserved.count - item.count,
            percentage: reserved.percentage - min,
          };
          changed = true;
        }
      }
    });

    if (changed) setMinPercentage();
  };

  setMinPercentage();

  data.forEach(item => {
    if (!item.percentage) {
      item.percentage = (item.count / reserved.count) * reserved.percentage;
    }
  });

  return data;
};

export const generatePoints = (
  data: Array<SankeyChartData>,
  bound: { x: number; y: number },
  textOffset: number,
  options: any,
) => {
  data = setMinimalValues(
    setDefaultValue(removeEmptyFields(data)),
    options.minPercentage || minimumPercentage,
  );

  const typesCount = data.length;

  const start: GraphStage = {
    height: bound.y / 3,
    padding: 1,
    contentHeight: 0,
  };

  const end: GraphStage = {
    height: typesCount < 2 ? bound.y / 3 : bound.y,
    padding: (options.rowsPadding || defaultRowPadding) - typesCount * 2,
    contentHeight: 0,
  };

  start.contentHeight = start.height - start.padding * (typesCount - 1);
  end.contentHeight = end.height - end.padding * (typesCount - 1);

  const offsetY = (bound.y - end.height) / 2;
  const firstItem = data[0].percentage || 0;

  const startPoint = {
    x: textOffset + (options.textMargin || 1),
    y:
      (firstItem * end.contentHeight) / 2 -
      (firstItem * start.contentHeight) / 2 +
      offsetY,
  };

  if (data[1] && data[1].percentage) {
    const secondItem = data[1].percentage || 0;

    startPoint.y +=
      (firstItem * end.contentHeight + secondItem * end.contentHeight) / 2 +
      end.padding -
      (firstItem * start.contentHeight + secondItem * start.contentHeight) / 2 +
      start.padding;
  }

  const transitionStartX = startPoint.x + smallRowsBecomeBigRange.start;
  const transitionEndX = startPoint.x + smallRowsBecomeBigRange.end;

  let currentStartHeight = startPoint.y;
  let currentEndHeight = offsetY;

  data.forEach((item, index) => {
    const localPoints: Array<[number, number]> = [];
    const percentage = item.percentage || 0;

    const statusStartHeight = start.contentHeight * percentage;
    const statusEndHeight = end.contentHeight * percentage;

    const transitionStart =
      transitionStartX +
      (index === 1 || typesCount === 0 ? rangeModifiersForCenterRow.start : 0);
    const transitionEnd =
      transitionEndX +
      (index === 1 || typesCount === 0 ? rangeModifiersForCenterRow.end : 0);

    localPoints.push([startPoint.x, currentStartHeight]);
    localPoints.push([startPoint.x, currentStartHeight]);
    localPoints.push([transitionStart, currentStartHeight]);
    localPoints.push([transitionEnd, currentEndHeight]);
    localPoints.push([transitionEnd + 5, currentEndHeight]);
    localPoints.push([bound.x, currentEndHeight]);
    localPoints.push([bound.x, currentEndHeight]);

    localPoints.push([bound.x, currentEndHeight + statusEndHeight]);
    localPoints.push([bound.x, currentEndHeight + statusEndHeight]);
    localPoints.push([transitionEnd + 5, currentEndHeight + statusEndHeight]);
    localPoints.push([transitionEnd, currentEndHeight + statusEndHeight]);
    localPoints.push([transitionStart, currentStartHeight + statusStartHeight]);
    localPoints.push([startPoint.x, currentStartHeight + statusStartHeight]);
    localPoints.push([startPoint.x, currentStartHeight + statusStartHeight]);

    data[index].points = localPoints;

    currentStartHeight += statusStartHeight + start.padding;
    currentEndHeight += statusEndHeight + end.padding;

    data[index].key = data[index].key.split(' ').join('');
  });

  return {
    textBlockHeight: currentStartHeight - startPoint.y - 1,
    charts: data,
    startPoint,
  };
};
