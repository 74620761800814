import React, { ReactNode } from 'react';
import { ReactComponent as ShortArrow } from '../../../shared/assets/images/shortArrow.svg';
import Button from '../../Button/Button';
import styles from './PopupMenuButton.module.scss';

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
}

const PopupMenuButton = ({ children, onClick }: Props) => {
  return (
    <Button
      type="none"
      onClick={onClick ? onClick : () => {}}
      className={styles.container}
    >
      {children}
      <ShortArrow className={styles.arrow} />
    </Button>
  );
};

export default PopupMenuButton;
