import React from 'react';
import styles from './Settings.module.scss';
import { useTranslation } from 'react-i18next';
import RadioButton from '../RadioButton/RadioButton';

const Languages = () => {
  const { i18n, t } = useTranslation();

  return (
    <section>
      <header className={styles.title}>{t('Settings.language')}</header>
      {[
        ['English', 'en'],
        ['Español', 'es'],
      ].map(([language, locale]) => (
        <div className={styles.item} key={language}>
          <RadioButton
            label={language}
            onChange={() => i18n.changeLanguage(locale)}
            checked={locale === i18n.language}
          />
        </div>
      ))}
    </section>
  );
};

export default Languages;
