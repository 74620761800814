import React from 'react';
import styles from './HorizontalBarChart.module.scss';

interface Props {
  data: {
    value: number;
    color: string;
  }[];
}

const HorizontalBarChart = ({ data }: Props) => {
  return (
    <div className={styles.wrapper}>
      {data.map(({ value, color }) => (
        <div
          key={color + value}
          className={styles.section}
          style={{ backgroundColor: color, flexGrow: value }}
        />
      ))}
    </div>
  );
};

export default HorizontalBarChart;
