import React from 'react';
import styles from './ValueField.module.scss';
import { Cell } from 'react-table';

const averageValue = (value: number) =>
  !isNaN(value) ? value.toFixed(2) : value;

interface Props {
  cell: Cell<any>;
}

const ValueField = ({ cell }: Props) => {
  const value = cell.value;
  const unit = cell.row.original.unit;

  return (
    <div className={styles.wrapper}>
      <span className={styles.value}>{value ? averageValue(value) : '-'}</span>
      <span className={styles.unit}>{value && unit}</span>
    </div>
  );
};

export default ValueField;
