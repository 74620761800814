import { useEffect, useState } from 'react';
import { getTargets } from '../api/analytics';
import { Target } from '../types/target';
import { useFetchData } from './useFetchData';

const colors = [
  {
    primary: '#9ACD32',
    secondary: '#E1F0C2',
  },
  {
    primary: '#FF7F50',
    secondary: '#ffccb9',
  },
  {
    primary: 'rgb(54, 157, 238)',
    secondary: 'rgba(54, 157, 238, 0.6)',
  },
  {
    primary: 'rgb(200, 35, 180)',
    secondary: 'rgba(200, 35, 180, 0.6)',
  },
  {
    primary: 'rgb(25, 171, 31)',
    secondary: 'rgba(255, 171, 31, 0.6)',
  },
];

export interface TargetColor {
  primary: string;
  secondary: string;
}

export interface TargetColors {
  [key: string]: {
    color: TargetColor;
    name: string;
  };
}

export function useTargetColors() {
  const [targetColors, setTargetColors] = useState<TargetColors>({});
  const [targets] = useFetchData<Target[]>(getTargets, []);

  useEffect(() => {
    const newTargetColors: TargetColors = {};

    targets.forEach((target, index) => {
      const color = colors[index % colors.length];

      newTargetColors[target.id] = { name: target.name, color };
    });

    setTargetColors(newTargetColors);
  }, [targets]);

  return targetColors;
}
