import React from 'react';
import SankeyChart from '../../SankeyChart/SankeyChart';
import { Recommendation } from '../../RecommendationTable/types';
import { getStatusColor } from '../../../shared/constants/statuses';
import { useTranslation } from 'react-i18next';

interface Props {
  recommendations: Recommendation[];
  statuses: Array<string>;
}

const PerformanceTotalCountChart = ({ recommendations, statuses }: Props) => {
  const { t } = useTranslation();

  const data = statuses.map(status => ({
    name: t(`Statuses.${status}`),
    color: getStatusColor(status),
    key: status.split(' ').join(''),
    count: recommendations.filter(s => s.status === status).length,
    points: [],
  }));

  const totalCount = data.reduce((a, b) => a + b.count, 0);

  return (
    <SankeyChart
      height={105}
      data={data}
      id="performanceChart"
      tooltipText={(count, name) =>
        t('RecommendationsChart.groupQuantity', { count, name })
      }
      text={t('RecommendationsChart.groupQuantity', {
        count: totalCount,
        name: '',
      })}
      options={{
        textMargin: 70,
      }}
    />
  );
};

export default PerformanceTotalCountChart;
