import React, { ReactNode } from 'react';
import styles from './Subheader.module.scss';
import classNames from 'classnames';

interface Props {
  title?: string | ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  staticTheme?: boolean;
  overrideClassName?: string;
}

const Subheader = ({
  title,
  left,
  right,
  staticTheme,
  overrideClassName,
}: Props) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        staticTheme && styles.darkTheme,
        overrideClassName,
      )}
    >
      <div className={styles.section}>
        {title && <div className={styles.title}>{title}</div>}
        {left}
      </div>
      <div className={styles.section}>{right}</div>
    </div>
  );
};

export default Subheader;
