import React, { ReactNode, useState } from 'react';
import PopupMenuButton from '../PopupMenu/PopupMenuButton/PopupMenuButton';
import styles from './DropdownMenu.module.scss';

interface Props {
  name: string;
  children: ReactNode;
}

const DropdownSubMenu = ({ name, children }: Props) => {
  const [isSelected, toggleSelect] = useState(false);

  return (
    <span
      onMouseEnter={() => toggleSelect(true)}
      onMouseLeave={() => toggleSelect(false)}
      data-testid="filter-menu-option"
    >
      <PopupMenuButton data-testid="filter-menu-option" key={name}>
        <div className={styles.menuOptionTitle}>{name}</div>
        {isSelected && <div className={styles.subMenu}>{children}</div>}
      </PopupMenuButton>
    </span>
  );
};

export default DropdownSubMenu;
