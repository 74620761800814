import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRecommendations } from '../../shared/api/performance';
import { AppContext } from '../../shared/AppContext';
import STATUSES from '../../shared/constants/statuses';
import { callWithErrorHandler } from '../../shared/services/ErrorHandler/callWithErrorHandler';
import { DatePeriod } from '../../shared/utils/getDatePeriodRange';
import ExtendedDatePicker from '../ExtendedDatePicker/ExtendedDatePicker';
import { Recommendation } from '../RecommendationTable/types';
import Subheader from '../Subheader/Subheader';
import PerformanceFilters from './PerformanceFilters/PerformanceFilters';
import styles from './PerformanceSummary.module.scss';
import PerformanceTable from './PerformanceTable/PerformanceTable';

const ALLOWED_STATUSES_LIST = [
  STATUSES.approved,
  STATUSES.declined,
  STATUSES.pending,
];

const PerformanceSummary = () => {
  const { serverSentEventId } = useContext(AppContext);
  const { t } = useTranslation();

  const [dateTo, setDateTo] = useState<Date>();
  const [dateFrom, setDateFrom] = useState<Date>();
  const [isDataFetched, setDataFetched] = useState(false);
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);

  const [filteredRecommendations, setFilteredRecommendations] = useState<
    Recommendation[]
  >([]);

  const [currentFilter, setFilter] = useState<{
    group: string;
    value: string;
  }>({ group: '', value: '' });

  useEffect(() => {
    if (dateTo && dateFrom) {
      setDataFetched(false);
      callWithErrorHandler(async () => {
        const { data } = await getRecommendations(dateFrom, dateTo);
        // exclude pending recommendations
        setRecommendations(
          data.filter((rec: Recommendation) =>
            ALLOWED_STATUSES_LIST.includes(rec.status),
          ),
        );
        setDataFetched(true);
      });
    }
  }, [dateFrom, dateTo, serverSentEventId]);

  const updateFilter = useCallback(
    (group: string, value: string) => {
      setFilter({ group, value });
    },
    [setFilter],
  );

  useEffect(() => {
    setFilteredRecommendations(
      recommendations.filter(
        (rec: any) => rec[currentFilter.group] === currentFilter.value,
      ),
    );
  }, [recommendations, currentFilter]);

  return (
    <div>
      <Subheader
        title={t('Performance.performanceSummary')}
        right={
          <ExtendedDatePicker
            dateTo={dateTo}
            dateFrom={dateFrom}
            setDateTo={setDateTo}
            setDateFrom={setDateFrom}
            defaultDatePeriod={DatePeriod['7days']}
          />
        }
      />
      <div className={styles.content}>
        <PerformanceFilters
          currentFilter={currentFilter}
          setFilter={updateFilter}
          recommendations={recommendations}
        />
        <PerformanceTable
          isDataFetched={isDataFetched}
          statuses={ALLOWED_STATUSES_LIST}
          data={
            currentFilter.value === '' || filteredRecommendations.length === 0
              ? recommendations
              : filteredRecommendations
          }
          filter={currentFilter}
        />
      </div>
    </div>
  );
};

export default PerformanceSummary;
