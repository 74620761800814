export enum DatePeriod {
  '30days' = '30days',
  '7days' = '7days',
  '24hours' = '24hours',
  'all' = 'all',
  'currentRun' = 'currentRun',
}

export const getDatePeriodRange = (datePeriod: DatePeriod) => {
  const initialDateTo = new Date();
  const initialDateFrom = new Date(initialDateTo);

  if (datePeriod === DatePeriod['30days']) {
    initialDateFrom.setDate(initialDateFrom.getDate() - 29);
  }

  if (datePeriod === DatePeriod['7days']) {
    initialDateFrom.setDate(initialDateFrom.getDate() - 6);
  }

  if (datePeriod === DatePeriod['24hours']) {
    initialDateFrom.setDate(initialDateFrom.getDate() - 1);
  }

  return {
    dateFrom: initialDateFrom,
    dateTo: initialDateTo,
  };
};
