import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import analyticsApi from '../../shared/api/appApi';
import { useChart, UseChartDataset } from '../../shared/hooks/useChart';
import { callWithErrorHandler } from '../../shared/services/ErrorHandler/callWithErrorHandler';
import ThemeContext from '../../shared/themes/ThemeContext';
import {
  getDayEnd,
  getDayStart,
  getISODateWithoutMilliseconds,
} from '../../shared/utils/dateHelpers';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import styles from './TagValuesChart.module.scss';
import ContentPlaceholder from '../ContentPlaceholder/ContentPlaceholder';
import range from 'lodash/range';

const TagValuesChart = ({ tagId }: { tagId: string }) => {
  const { getCSSVariable } = useContext(ThemeContext);
  const { t } = useTranslation();

  const [chartData, setChartData] = useState<UseChartDataset[]>([]);

  const [isDatePickerFromVisible, setDatePickerFromVisible] = useState(false);
  const [isDatePickerToVisible, setDatePickerToVisible] = useState(false);
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [isDataFetched, setDataFetched] = useState(false);

  const [canvasRef] = useChart(chartData);

  useEffect(() => {
    const initialDateTo = new Date();
    const initialDateFrom = new Date(initialDateTo);
    // show data for the last 3 days if data is present
    initialDateFrom.setDate(initialDateFrom.getDate() - 3);

    setDateFrom(initialDateFrom);
    setDateTo(initialDateTo);
  }, []);

  useEffect(() => {
    if (!dateFrom || !dateTo) return;

    callWithErrorHandler(async () => {
      setDataFetched(false);
      const { data: recommendedTagValues } = await analyticsApi.get(
        `/recommended_tag_values/${tagId}`,
        {
          params: {
            date_from: getISODateWithoutMilliseconds(getDayStart(dateFrom)),
            date_to: getISODateWithoutMilliseconds(getDayEnd(dateTo)),
          },
        },
      );
      const { data: actualTagValues } = await analyticsApi.get(
        `/measurements/${tagId}`,
        {
          params: {
            date_from: getISODateWithoutMilliseconds(getDayStart(dateFrom)),
            date_to: getISODateWithoutMilliseconds(getDayEnd(dateTo)),
          },
        },
      );
      const { data: recommended_min_implentation_values } = await analyticsApi.get(
        `/recommended_min_implentation_values/${tagId}`,
        {
          params: {
            date_from: getISODateWithoutMilliseconds(getDayStart(dateFrom)),
            date_to: getISODateWithoutMilliseconds(getDayEnd(dateTo)),
          },
        },
      );
      const { data: recommended_max_implentation_values } = await analyticsApi.get(
        `/recommended_max_implentation_values/${tagId}`,
        {
          params: {
            date_from: getISODateWithoutMilliseconds(getDayStart(dateFrom)),
            date_to: getISODateWithoutMilliseconds(getDayEnd(dateTo)),
          },
        },
      );

      setChartData([
        {
          data: recommendedTagValues,
          stepped: true,
          pointRadius: 0,
          borderWidth: 2,
          borderColor: getCSSVariable('--secondary-color'),
        },
        { data: actualTagValues, pointRadius: 0 },
        {
          data: recommended_min_implentation_values,
          borderColor: 'green',
          stepped: true,
          pointRadius: 0,
          borderWidth: 2,
          borderDash: [5, 5],
        },
        {
          data: recommended_max_implentation_values,
          borderColor: 'green',
          stepped: true,
          pointRadius: 0,
          borderWidth: 2,
          borderDash: [5, 5],
        },
      ]);
      setDataFetched(true);
    });
  }, [dateFrom, dateTo, getCSSVariable, tagId]);

  const contentExist = chartData.some(dataset => dataset.data.length > 0);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('TimeSeriesChart.title')}</div>

      <div className={styles.datePickers}>
        <DatePickerInput
          text={t('DownloadMenu.fromDate')}
          dateToDisplay={dateFrom}
          selectDate={setDateFrom}
          dateFrom={dateFrom}
          dateTo={dateTo}
          disabledDays={dateTo && { after: dateTo }}
          datePickerClassName={styles.datePicker}
          inputClassName={styles.datePickerInput}
          isDatePickerVisible={isDatePickerFromVisible}
          setDatePickerVisible={setDatePickerFromVisible}
          inputIconColor="var(--secondary-color)"
        />

        <DatePickerInput
          text={t('DownloadMenu.toDate')}
          dateToDisplay={dateTo}
          selectDate={setDateTo}
          dateFrom={dateFrom}
          dateTo={dateTo}
          disabledDays={dateFrom && { before: dateFrom }}
          datePickerClassName={styles.datePicker}
          inputClassName={styles.datePickerInput}
          isDatePickerVisible={isDatePickerToVisible}
          setDatePickerVisible={setDatePickerToVisible}
          inputIconColor="var(--secondary-color)"
        />
      </div>

      <div className={styles.legend}>
        <div className={styles.legendItem}>
          <div
            className={classNames(styles.legendBox, styles.recommendedLegend)}
          />
          {t('TimeSeriesChart.recommended')}
        </div>

        <div className={styles.legendItem}>
          <div className={styles.legendBox} />
          {t('TimeSeriesChart.actual')}
        </div>
        <div className={styles.legendItem}>
        <div className={classNames(styles.legendBox, styles.rangeLegend)} />
          {t('Rango de Implementación')}
        </div>
      </div>

      <div className={styles.chart}>
        <ContentPlaceholder
          isLoading={!isDataFetched}
          doesContentExist={contentExist}
          noContentTitle={t('TimeSeriesChart.noData')}
        >
          <canvas ref={canvasRef} />
        </ContentPlaceholder>
      </div>
    </div>
  );
};

export default TagValuesChart;
