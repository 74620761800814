import { useContext } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';

import SignOutButton from '../SignOutButton/SignOutButton';
import SessionContext from '../../shared/contexts/SessionContext';

import styles from './TopBar.module.scss';

const TopBar = () => {
  const { user } = useContext(SessionContext);
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return (
      <div className={styles.container}>
        <span className={styles.userInfo}>
          {user?.userName || localStorage.getItem('user_name')}
        </span>
        <div className={styles.buttons}>
          <SignOutButton />
        </div>
      </div>
    );
  }

  return <></>;
};

export default TopBar;
