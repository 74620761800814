import React, { useContext, useEffect } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';

import { AppContext } from '../../shared/AppContext';

import { getApiUrl } from '../../shared/api/shared';
import generateClientId from '../../shared/utils/clientId';
import { generateRandomId } from '../../shared/utils/generateRandomId';

export default function ServerEvents(): JSX.Element {
  const { setServerSentEventId } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      const id = generateClientId();
      const eventSource = new EventSourcePolyfill(
        `${getApiUrl()}/server-events?id=` + id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      eventSource.addEventListener('server_event', () =>
        setServerSentEventId(generateRandomId()),
      );

      eventSource.onopen = () =>
        console.log('SSE connected, waiting for server pushes');

      // suppress reconnection error
      eventSource.onerror = (e: any) => {
        if (!e.error || !e.error.message.includes('No activity')) {
          console.error(e.error ? e.error : e);
        }
      };

      return () => {
        eventSource.close();
      };
    })();
  }, [setServerSentEventId]);

  return <></>;
}
