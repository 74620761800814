import { getEnvironmentVariables } from '../environment';
import { obtainTokenApim } from './tokenLogin';
import appApi from './appApi';
import { getISODateWithoutMilliseconds } from '../../shared/utils/dateHelpers';
import { AxiosResponse } from 'axios';


const getAPIMToken = async () => {
    const { enableAPIManagement } = getEnvironmentVariables();
    if (enableAPIManagement) {
      const tokenResult = await obtainTokenApim();
      localStorage.setItem('token', tokenResult);
    }
  };


const getTablesInfo = async (
  dateFrom: Date,
  dateTo: Date,
): Promise<AxiosResponse<Databases, any>> => {
  await getAPIMToken();
  const result = await appApi.get('db_stats', {
    params: {
      date_from: getISODateWithoutMilliseconds(dateFrom),
      date_to: getISODateWithoutMilliseconds(dateTo),
    },
  });
  return result;
};

  const getCurrentRun = async (): Promise<AxiosResponse<any, any>> => {
    await getAPIMToken();
    const result = await appApi.get('runs/current');
    return result;
  };



  export{
    getTablesInfo,
    getCurrentRun
  }