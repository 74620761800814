export function getEnvironmentVariables() {
  const all_variables = {
    apiURL: process.env.REACT_APP_OPTIMUS_ANALYTICS_API,
    enableServerEvents: process.env.REACT_APP_ENABLE_SERVER_EVENTS === 'true',
    // TODO: define allow values: development | testing | production
    environment: process.env.REACT_APP_DEVELOPMENT,
    // Active directory integration
    authClientId: process.env.REACT_APP_AD_CLIENT_ID || '',
    authUrl: process.env.REACT_APP_AUTH_URL || '',
    authRedirectUrl: process.env.REACT_APP_AUTH_REDIRECT_URL || '',
    enableADIntegration:
      process.env.REACT_APP_ENABLE_AD_INTEGRATION === 'true' || false,
    enableAPIManagement:
      process.env.REACT_APP_ENABLE_APIM_INTEGRATION === 'true' || false,
    enableBTNReview:
      process.env.REACT_APP_ENABLE_BTN_REVIEW === 'true' || false,
    apimURL: process.env.REACT_APP_APIM_URL,
  };
  return all_variables;
}
