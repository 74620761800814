import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TargetColor } from '../../shared/hooks/useTargetColors';
import { ObjectiveFunction } from '../../shared/types/target';
import {
  formatToPercentage,
  formatToShortNumber,
} from '../../shared/utils/formatNumbers';
import styles from './PredictionView.module.scss';
import { Prediction } from './types';

interface Props {
  prediction: Prediction;
  color: TargetColor;
}

const PredictionView = ({ prediction, color }: Props) => {
  const { t } = useTranslation();
  const [isDifferencePositive, setDifferencePositive] = useState<boolean>();

  useEffect(() => {
    if (prediction.objective === ObjectiveFunction.min) {
      setDifferencePositive(prediction.difference < 0);
    } else {
      setDifferencePositive(prediction.difference > 0);
    }
  }, [prediction]);

  const maxValue = Math.max(prediction.baseline, prediction.optimized);

  const formatValue = (value: number) =>
    prediction.unit === '%'
      ? formatToPercentage(value)
      : formatToShortNumber(value);

  const getDifferenceIndicatorWord = () => {
    if (prediction.objective === ObjectiveFunction.max) {
      if (isDifferencePositive) return t('PredictionsView.uplift');

      return t('PredictionsView.decrease');
    }

    if (isDifferencePositive) return t('PredictionsView.reduction');

    return t('PredictionsView.increase');
  };

  return (
    <div className={styles.container}>
      <div className={styles.predictionName}>
        {prediction.name}
        {prediction.unit && <span> ({prediction.unit})</span>}
      </div>

      {prediction.recommendation_count > 0 && (
        <div className={styles.newRecommendations}>
          {t('PredictionsView.new_recommendations', {
            count: prediction.recommendation_count,
          })}
        </div>
      )}

      <div className={styles.legend}>
        <div className={styles.legendTextContainer}>
          <div
            style={{ backgroundColor: color.secondary }}
            className={styles.legendSymbol}
          />
          <div>{t('PredictionsView.baseline')}</div>
        </div>

        <div className={styles.legendSeparator}>{t('PredictionsView.vs')}</div>

        <div className={styles.legendTextContainer}>
          <div
            style={{ backgroundColor: color.primary }}
            className={styles.legendSymbol}
          />
          <div>{t('PredictionsView.optimized')}</div>
        </div>
      </div>

      <div className={styles.values}>
        <div>
          <div style={{ color: color.primary }} className={styles.difference}>
            {formatValue(Math.abs(prediction.difference))}

            <span className={styles.differenceIndicator}>
              {getDifferenceIndicatorWord()}
            </span>
          </div>
        </div>

        <div className={styles.charts}>
          <>
            <span className={styles.value}>
              {formatValue(prediction.baseline)}
            </span>

            <div
              className={styles.chart}
              style={{
                backgroundColor: color.secondary,
                width: (prediction.baseline * 100) / maxValue,
              }}
            />
          </>

          <>
            <span className={styles.value}>
              {formatValue(prediction.optimized)}
            </span>

            <div
              className={styles.chart}
              style={{
                backgroundColor: color.primary,
                width: (prediction.optimized * 100) / maxValue,
              }}
            />
          </>
        </div>
      </div>
    </div>
  );
};

export default PredictionView;
