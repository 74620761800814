export enum ROUTES {
  initial = '/',
  anomalies = '/anomalies',
  impactTracking = '/impact-tracking',
  performance = '/performance',
  reporting = '/reporting',
  database = '/database',
  openApiDocumentation = '/open-api-documentation',
  showcase = '/showcase',
  demo = '/demo',
  authCallback = '/auth-callback',
}
