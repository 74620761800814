import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import DatePeriodPicker from '../../components/DatePeriodPicker/DatePeriodPicker';
import PopupIconButton, {
  preventPopupClosingOnClick,
} from '../../components/IconButton/PopupIconButton';
import Preloader from '../../components/Preloader/Preloader';
import Tooltip from '../../components/Tooltip/Tooltip';
import Subheader from '../../components/Subheader/Subheader';
import ContentPlaceholder from '../../components/ContentPlaceholder/ContentPlaceholder';
import { ReactComponent as CloseIcon } from '../../shared/assets/images/closeIcon.svg';
import { ReactComponent as DownloadIcon } from '../../shared/assets/images/downloadIcon.svg';
import { ReactComponent as EmailIcon } from '../../shared/assets/images/emailIcon.svg';
import { ReactComponent as NotesIcon } from '../../shared/assets/images/notesIcon.svg';
import { useFetchData } from '../../shared/hooks/useFetchData';
import { callWithErrorHandler } from '../../shared/services/ErrorHandler/callWithErrorHandler';
import Notifier from '../../shared/services/Notifier/Notifier';
import { downloadFile } from '../../shared/utils/downloadFile';
import {
  DatePeriod,
  getDatePeriodRange,
} from '../../shared/utils/getDatePeriodRange';
 import {
   getReport as getReportApi,
   downloadPDFReport as downloadPDFReportApi,
   sendReport as sendReportApi,
 } from '../../shared/api/reporting';

import styles from './ReportingPage.module.scss';

const ReportingPage = () => {
  const [datePeriod, setDatePeriod] = useState<DatePeriod>(
    DatePeriod['24hours'],
  );
  const [recipients, setRecipients] = useState<Array<string>>([]);
  const [recipientInput, setRecipientInput] = useState('');
  const [waitingForMail, setWaitingForMail] = useState(false);
  const [commentTextarea, setCommentTextarea] = useState('');
  const [comment, setComment] = useState('');

  const { i18n } = useTranslation();

  const getReport = useCallback(() => {
    const { dateFrom, dateTo } = getDatePeriodRange(datePeriod);
    return getReportApi(dateFrom, dateTo, comment, i18n);
  }, [comment, datePeriod, i18n.language]);

  const [email, , isDataFetched] = useFetchData<string>(getReport, '');

  const downloadPDFReport = () => {
    callWithErrorHandler(async () => {
      const { dateFrom, dateTo } = getDatePeriodRange(datePeriod);

      const report = await downloadPDFReportApi(
        dateFrom,
        dateTo,
        comment,
        i18n,
      );

      downloadFile(report);
    });
  };

  async function sendReport(recipients: Array<string>) {
    const { dateFrom, dateTo } = getDatePeriodRange(datePeriod);

    return sendReportApi(dateFrom, dateTo, comment, i18n, recipients);
  }

  const sendMail = () => {
    callWithErrorHandler(async () => {
      // force popup to close
      document.dispatchEvent(new Event('click'));
      setWaitingForMail(true);
      const { data } = await sendReport(recipients);
      setWaitingForMail(false);

      if (data['message_was_sent']) {
        Notifier.show('Mail was sent');
      } else {
        Notifier.show('Mail was not sent', { type: 'error', duration: 9000 });
        Notifier.show(data['errorMessage'], { type: 'error', duration: 9000 });
      }

      if (data['missed_credentials']) {
        const env_list = data['missed_credentials'].join(', ');
        Notifier.show(
          `Mail credentials are missing, please set ENV: ${env_list}`,
          { type: 'error', duration: 10000 },
        );
      }
    });
  };

  const addRecipient = (newRecipient: string) => {
    setRecipients([...recipients, newRecipient]);
    setRecipientInput('');
  };

  const removeRecipient = (e: any, recipient: string) => {
    e.stopPropagation();
    setRecipients(recipients.filter(entity => entity !== recipient));
  };

  return (
    <>
      <Subheader
        right={
          <>
          <DatePeriodPicker
            datePeriod={datePeriod}
            onDatePeriodChange={setDatePeriod}
            className={styles.datePeriodPicker}
            periods={[DatePeriod['7days'], DatePeriod['24hours']]}
          />
            <PopupIconButton
              icon={<NotesIcon />}
              tooltip={comment ? 'Change Comment' : 'Add Comment'}
            >
              <div
                className={styles.popupContentWrapper}
                onClick={preventPopupClosingOnClick}
              >
                <textarea
                  value={commentTextarea}
                  placeholder="Write a comment here"
                  onChange={e => setCommentTextarea(e.target.value)}
                  className={styles.commentTextarea}
                />

                <button
                  className={styles.popupButton}
                  onClick={() => {
                    // force popup to close
                    document.dispatchEvent(new Event('click'));
                    setComment(commentTextarea);
                  }}
                >
                  {comment ? 'Change Comment' : 'Add Comment'}
                </button>
              </div>
            </PopupIconButton>

            <Tooltip text="Export as PDF" position="bottom">
              <button
                className={styles.downloadIcon}
                onClick={downloadPDFReport}
              >
                <DownloadIcon />
              </button>
            </Tooltip>

            <PopupIconButton
              icon={waitingForMail ? <Preloader /> : <EmailIcon />}
              tooltip="Send Mail"
            >
              <div className={styles.popupContentWrapper}>
                <div className={styles.recipientsTitle}>Recipients:</div>
                <div className={styles.recipientsList}>
                  {recipients.length > 0 ? (
                    recipients.map((mail, index) => (
                      <button
                        key={mail + index}
                        className={styles.recipient}
                        onClick={e => removeRecipient(e, mail)}
                      >
                        {mail}
                        <CloseIcon />
                      </button>
                    ))
                  ) : (
                    <span className={styles.noRecipients}>
                      No recipients have been added
                    </span>
                  )}
                </div>
                <div
                  className={styles.addRecipientWrapper}
                  onClick={preventPopupClosingOnClick}
                >
                  <input
                    className={styles.recipientInput}
                    placeholder="Enter email"
                    value={recipientInput}
                    onChange={e => setRecipientInput(e.target.value)}
                    type="email"
                  />
                  <button
                    onClick={() => addRecipient(recipientInput)}
                    className={styles.addRecipientButton}
                  >
                    +
                  </button>
                </div>

                <button className={styles.popupButton} onClick={sendMail}>
                  Send mail
                </button>
              </div>
            </PopupIconButton>
          </>
        }
        overrideClassName={styles.bar}
      />

      <ContentPlaceholder isLoading={!isDataFetched}>
        <iframe
          title="email_preview"
          frameBorder="0"
          className={classNames(styles.email, styles.fadeInAnimation)}
          src={'data:text/html,' + encodeURIComponent(email)}
        />
      </ContentPlaceholder>
    </>
  );
};

export default ReportingPage;
