import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import ExtendedDatePicker from '../../components/ExtendedDatePicker/ExtendedDatePicker';
import HistoricalMetrics from '../../components/HistoricalMetrics/HistoricalMetrics';
import ImpactTracker from '../../components/ImpactTracker/ImpactTracker';
import Subheader from '../../components/Subheader/Subheader';
import ContentPlaceholder from '../../components/ContentPlaceholder/ContentPlaceholder';
import CommonError from '../../custom_components/CommonError/CommonError';
import Loading from '../../custom_components/Loading/Loading';
import { getTargets } from '../../shared/api/impactTracking';
import { useFetchData } from '../../shared/hooks/useFetchData';
import { Target } from '../../shared/types/target';
import { getEnvironmentVariables } from '../../shared/environment';
import { getLoginRequest } from '../../shared/api/authConfig';

import styles from './ImpactTracking.module.scss';

const ImpactTrackingContent = () => {
  const { t } = useTranslation();

  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [targets, , isDataFetched] = useFetchData<Target[]>(getTargets, []);

  return (
    <div>
      <Subheader
        title={t('ImpactTracking.title')}
        right={
          <ExtendedDatePicker
            dateTo={dateTo}
            dateFrom={dateFrom}
            setDateTo={setDateTo}
            setDateFrom={setDateFrom}
          />
        }
      />
      <ContentPlaceholder
        isLoading={!isDataFetched}
        doesContentExist={targets.length > 0}
        noContentTitle={t('ImpactTracking.noTargets')}
        height={300}
      >
        <div className={styles.trackers}>
          {targets.map((target: Target) => (
            <div
              className={styles.trackerContainer}
              key={`tracker-${target.id}`}
            >
              <ImpactTracker
                target={target}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />

              <HistoricalMetrics
                target={target}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            </div>
          ))}
        </div>
      </ContentPlaceholder>
    </div>
  );
};

const ImpactTracking = () => {
  const { enableADIntegration } = getEnvironmentVariables();

  if (!enableADIntegration) {
    return <ImpactTrackingContent />;
  }

  const specificUrl = '/impact-tracking';
  const authRequest = getLoginRequest(specificUrl);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={CommonError}
      loadingComponent={Loading}
    >
      <ImpactTrackingContent />
    </MsalAuthenticationTemplate>
  );
};

export default ImpactTracking;
