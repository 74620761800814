import { useEffect, useContext } from 'react';

import { getAuthToken } from '../../shared/api/appApi';
import SessionContext from '../../shared/contexts/SessionContext';

const Auth2 = () => {
  const { setAuthTokenFetched } = useContext(SessionContext);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        await getAuthToken();
        setAuthTokenFetched(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};

export default Auth2;
