import { useEffect, useState } from 'react';
import { callWithErrorHandler } from '../../../shared/services/ErrorHandler/callWithErrorHandler';
import { Target } from '../../../shared/types/target';
import {
  DatePeriod,
  getDatePeriodRange,
} from '../../../shared/utils/getDatePeriodRange';
import { 
  getNewActual,
  getTargetsPredictions
 } from '../../../shared/api/impactTracking';

export function useHistoricalMetrics({
  target,
  dateFrom,
  dateTo,
  period,
}: {
  target: Target;
  dateFrom?: Date;
  dateTo?: Date;
  period?: DatePeriod;
}) {
  const [actual, setActual] = useState<number>();
  const [difference, setDifference] = useState<number>();

  useEffect(() => {
    callWithErrorHandler(async () => {
      let dateFromParam: Date | undefined;
      let dateToParam: Date | undefined;

      if (dateFrom && dateTo) {
        dateFromParam = dateFrom;
        dateToParam = dateTo;
      }

      if (period) {
        const dateRange = getDatePeriodRange(period);
        dateFromParam = dateRange.dateFrom;
        dateToParam = dateRange.dateTo;
      }

      if (!dateFromParam || !dateToParam) return;

      const { data: newActual } = await getNewActual(target,dateFromParam,dateToParam);
      setActual(newActual.result);
      
      const { data: predictions } = await getTargetsPredictions(target,dateFromParam,dateToParam);
      setDifference(newActual.result - predictions.baseline);
    });
  }, [target, dateFrom, dateTo, period]);

  return { actual, difference };
}
