import axios from 'axios';
import { getEnvironmentVariables } from '../environment';

export async function obtainTokenApim() {
  const { apimURL } = getEnvironmentVariables();
  let token = '';
  try {
    const response = await axios.get(`${apimURL}/Token/api/v1/get_token`);
    token = response.data.token;
  } catch (error) {
    console.error('Error obtaining token:', JSON.stringify(error));
  }
  return token;
}
