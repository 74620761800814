import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import AppRoutes from './custom_components/AppRoutes/AppRoutes';

interface AppProps {
  pca: IPublicClientApplication | undefined;
}

const App = ({ pca }: AppProps) => {
  if (!pca) {
    return <AppRoutes />;
  }

  return (
    <MsalProvider instance={pca}>
      <AppRoutes />
    </MsalProvider>
  );
};

export default App;
