import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getControlRoomRecommendations,
  updateRecommendationStatuses,
} from '../../shared/api/analytics';

import RecommendationTable from '../RecommendationTable/RecommendationTable';
import { Recommendation } from '../RecommendationTable/types';

import STATUSES from '../../shared/constants/statuses';
import { useFetchData } from '../../shared/hooks/useFetchData';
import { useTargetColors } from '../../shared/hooks/useTargetColors';
import { callWithErrorHandler } from '../../shared/services/ErrorHandler/callWithErrorHandler';
import Notifier from '../../shared/services/Notifier/Notifier';
import { RecommendationUpdateBody } from '../../shared/types/recommendationUpdateBody';

export interface HandleStatusChangeProps {
  recommendations: Recommendation[];
  status: string;
  comment?: string;
  newValue?: number;
  isMultipleMode?: boolean;
}

const RecommendationsView = () => {
  const [recommendations, , isDataFetched] = useFetchData<Recommendation[]>(
    getControlRoomRecommendations,
    [],
  );
  const [recommendationsById, setRecommendationByIds] = useState<{
    [key: string]: Recommendation;
  }>({});
  const targetColors = useTargetColors();
  const { t } = useTranslation();

  const prevRecommendations = useRef<{ [key: string]: string }>({});

  useEffect(() => {
    setRecommendationByIds(
      recommendations.reduce(
        (previous, current) => ({ ...previous, [current.id]: current }),
        {},
      ),
    );

    let recommendationsChanged = 0;

    recommendations.forEach(rec => {
      const recJson = JSON.stringify({ id: rec });
      if (prevRecommendations.current[rec.id] !== recJson) {
        recommendationsChanged++;
        prevRecommendations.current[rec.id] = recJson;
      }
    });

    if (recommendationsChanged > 0) {
      Notifier.show(
        t('Notifications.updatedRecommendations', {
          count: recommendationsChanged,
        }),
        { duration: 5000 },
      );
    }
  }, [t, recommendations]);

  const updateRecommendationsState = (recommendations: Recommendation[]) =>
    setRecommendationByIds(prevState => {
      const newState = { ...prevState };

      for (const recommendation of recommendations) {
        newState[recommendation.id] = {
          ...prevState[recommendation.id],
          ...recommendation,
        };
      }

      return newState;
    });

  const handleStatusChange = ({
    recommendations,
    status,
    newValue,
    comment = '',
    isMultipleMode = false,
  }: HandleStatusChangeProps) => {
    callWithErrorHandler(async () => {
      const recommendationsToUpdate = recommendations.map(rec => {
        const recToUpdate: RecommendationUpdateBody = {
          id: rec.id,
          status,
          comment,
        };

        if (!process.env.REACT_APP_USE_DYNAMIC_RECS) return recToUpdate;

        if (isMultipleMode && status === STATUSES.declined) {
          recToUpdate.new_value = rec.current;
        } else if (newValue) {
          recToUpdate.new_value = newValue;
        }

        return recToUpdate;
      });

      const updatedRecommendations = await updateRecommendationStatuses(
        recommendationsToUpdate,
      );

      Notifier.show(t('Notifications.statusChanged'));
      updateRecommendationsState(updatedRecommendations);
    });
  };

  const headerContent = <></>;

  const sortedRecommendations = Object.values(recommendationsById).sort(
    (a, b) => {
      if (a.tag_id === 'presion_hidraulica_izquierdo_CR204_filtrado') return -1;
      if (b.tag_id === 'presion_hidraulica_izquierdo_CR204_filtrado') return 1;

      if (a.tag_id === 'tonelaje_seco_alimentacion_molino_ml201') return -1;
      if (b.tag_id === 'tonelaje_seco_alimentacion_molino_ml201') return 1;

      if (a.tag_id === 'calc_pct_solids_bm') return -1;
      if (b.tag_id === 'calc_pct_solids_bm') return 1;

      if (a.tag_id === 'ciclones_presion') return -1;
      if (b.tag_id === 'ciclones_presion') return 1;

      if (a.tag_id === 'calc_solidos_rougher') return -1;
      if (b.tag_id === 'calc_solidos_rougher') return 1;

      if (a.tag_id === 'calc_promotor_grt_recommended') return -1;
      if (b.tag_id === 'calc_promotor_grt_recommended') return 1;

      if (a.tag_id === 'flotacion_flujo_aire_celda_rougher_1') return -1;
      if (b.tag_id === 'flotacion_flujo_aire_celda_rougher_1') return 1;

      if (a.tag_id === 'flotacion_flujo_aire_celda_rougher_2') return -1;
      if (b.tag_id === 'flotacion_flujo_aire_celda_rougher_2') return 1;

      if (a.tag_id === 'flotacion_flujo_aire_celda_rougher_3') return -1;
      if (b.tag_id === 'flotacion_flujo_aire_celda_rougher_3') return 1;

      if (a.tag_id === 'flotacion_flujo_aire_celda_rougher_4') return -1;
      if (b.tag_id === 'flotacion_flujo_aire_celda_rougher_4') return 1;

      return a.tag_id.localeCompare(b.tag_id);
    },
  );

  return (
    <>
      <div id="recommendationsView">
        <RecommendationTable
          isDataFetched={isDataFetched}
          targetColors={targetColors}
          recommendations={sortedRecommendations}
          handleStatusChange={handleStatusChange}
          headerContent={headerContent}
        />
      </div>
    </>
  );
};

export default RecommendationsView;
