import * as msal from '@azure/msal-browser';
import { getEnvironmentVariables } from '../environment';

export const getAuthConfiguration = () => {
  const ua = window.navigator.userAgent;
  const msedge = ua.indexOf('Edge/');
  const firefox = ua.indexOf('Firefox');
  const isEdge = msedge > 0;
  const isFirefox = firefox > 0;

  const { authClientId, authUrl, authRedirectUrl } = getEnvironmentVariables();
  const msalConfig = {
    auth: {
      clientId: authClientId,
      authority: authUrl,
      redirectUri: authRedirectUrl,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isEdge || isFirefox,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback: (level: number, message: string, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
      },
    },
  };
  return msalConfig;
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

export const getLoginRequest = (specificUrl: string = '') => {
  const mainValues: any = {
    ...loginRequest,
  };

  if (specificUrl && specificUrl.length > 0) {
    mainValues['redirectUri'] = specificUrl;
  }

  return mainValues;
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
