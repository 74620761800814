import { useMsal } from '@azure/msal-react';
import classNames from 'classnames';

import styles from './SignOutButton.module.scss';

const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <button
      className={classNames(styles.button, styles.contained)}
      onClick={() => handleLogout()}
    >
      Signout
    </button>
  );
};

export default SignOutButton;
