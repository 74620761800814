import { useLayoutEffect, useState } from 'react';

// return ref sizes every time when window is resized
const useComponentSize = node => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    if (node) {
      function updateSize() {
        setSize([node.offsetWidth, node.offsetHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }
  }, [node]);
  return size;
};

export default useComponentSize;
