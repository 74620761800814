import FILTER_NAMES from '../constants/filterNames';
import { Row } from 'react-table';

const PLACE_ROW1_ABOVE = -1;
const PLACE_ROW2_ABOVE = 1;
const DONT_REPlACE = 0;

export const boolSorting = (
  row1: Row,
  row2: Row,
  id: string,
  desc?: boolean,
): number => {
  let bool1 = row1.values[id];
  let bool2 = row2.values[id];

  // react-table cant sort boolean values, with only last return it will sort elements by two
  // groups(with updates and without updates) and this groups could start from old and finish with
  // new elements, the next line fix it, so every group will start from new elements
  if (bool1 === bool2) {
    return desc ? PLACE_ROW1_ABOVE : DONT_REPlACE;
  }

  return bool2 ? PLACE_ROW2_ABOVE : PLACE_ROW1_ABOVE;
};

export const doesRecommendationHasChanges = (row: any): boolean => {
  const { recommended, current, tolerance } = row;

  if (!tolerance) return false;

  return Math.abs(current - recommended) > tolerance;
};

export const includesFilter = (
  rows: Array<Row<any>>,
  columnIds: string[],
  filterValues: Array<any>,
): Row<any>[] => {
  return rows.filter(row => {
    return (
      filterValues.includes(FILTER_NAMES.all) ||
      filterValues.includes(row.original[columnIds[0]])
    );
  });
};
