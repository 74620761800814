import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { generateRandomId } from './utils/generateRandomId';

interface AppContextState {
  serverSentEventId: number;
  setServerSentEventId: Dispatch<SetStateAction<number>>;
  showTooltipMarkers: boolean;
  setShowTooltipMarkers: Dispatch<SetStateAction<boolean>>;
  dynamicRecsLoading: boolean;
  setDynamicRecsLoading: Dispatch<SetStateAction<boolean>>;
}

const defaultState: AppContextState = {
  serverSentEventId: generateRandomId(),
  setServerSentEventId: () => {},
  showTooltipMarkers: false,
  setShowTooltipMarkers: () => {},
  dynamicRecsLoading: false,
  setDynamicRecsLoading: () => {},
};

export const AppContext = React.createContext<AppContextState>(defaultState);

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [serverSentEventId, setServerSentEventId] = useState(
    defaultState.serverSentEventId,
  );

  const [showTooltipMarkers, setShowTooltipMarkers] = useState(
    defaultState.showTooltipMarkers,
  );

  const [dynamicRecsLoading, setDynamicRecsLoading] = useState(false);

  return (
    <AppContext.Provider
      value={{
        serverSentEventId,
        setServerSentEventId,
        showTooltipMarkers,
        setShowTooltipMarkers,
        dynamicRecsLoading,
        setDynamicRecsLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
