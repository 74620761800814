import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';

import Button from '../Button/Button';
import ExpandContent from '../ExpandContent/ExpandContent';
import { HandleStatusChangeProps } from '../RecommendationsView/RecommendationsView';
import { Recommendation } from '../RecommendationTable/types';
import { CommentTextarea } from '../StatusChange/CommentTextarea/CommentTextarea';
import { StatusButtons } from '../StatusChange/StatusButtons/StatusButtons';
import TagValuesChart from '../TagValuesChart/TagValuesChart';

import STATUSES from '../../shared/constants/statuses';

import { ReactComponent as ShortArrow } from '../../shared/assets/images/shortArrow.svg';
import styles from './RecommendationDetails.module.scss';

interface Props {
  row: Row<Recommendation>;
  handleStatusChange: (props: HandleStatusChangeProps) => void;
}

const RecommendationDetails = ({ row, handleStatusChange }: Props) => {
  const { t } = useTranslation();
  const [showChart, setShowChart] = useState(false);
  const [comment, setComment] = useState('');

  if (
    process.env.REACT_APP_USE_DYNAMIC_RECS &&
    row.original.status !== STATUSES.pending
  ) {
    return (
      <div className={classNames(styles.container, styles.darkTheme)}>
        <TagValuesChart tagId={row.original.tag_id} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.detailsShort}>
        <Button type="text" onClick={() => setShowChart(!showChart)}>
          {showChart
            ? t('RecommendationDetails.closeChart')
            : t('RecommendationDetails.openChart')}

          <div
            className={classNames(
              styles.toggleChartIcon,
              !showChart && styles.openIcon,
            )}
          >
            <ShortArrow className={styles.arrowIcon} />
          </div>
        </Button>

        <CommentTextarea comment={comment} setComment={setComment} />

        <StatusButtons
          recommendation={row.original}
          handleStatusChange={(status, newValue) => {
            row.toggleRowExpanded(false);
            handleStatusChange({
              recommendations: [row.original],
              status,
              comment,
              newValue,
            });
          }}
        />
      </div>

      <ExpandContent expand={showChart} duration={800}>
        <div className={styles.tagValuesChart}>
          <TagValuesChart tagId={row.original.tag_id} />
        </div>
      </ExpandContent>
    </div>
  );
};

export default RecommendationDetails;
