import classNames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';
import { Row } from 'react-table';
import config from '../../../application.json';
import { doesRecommendationHasChanges } from '../../../shared/utils/tableHelper';
import ExpandContent from '../../ExpandContent/ExpandContent';
import styles from './TableRow.module.scss';

interface RowData {
  isLoading?: boolean;
  [x: string]: any;
}

interface Props {
  row: Row<RowData>;
  expandedRowBody?: (row: Row<RowData>) => ReactNode;
  style?: CSSProperties;
  rowClassName?: string;
  getRowStyles?: (row: Row<RowData>) => { [name: string]: string };
}

const TableRow = ({
  row,
  expandedRowBody,
  style,
  rowClassName,
  getRowStyles,
}: Props) => {
  let computedStyles = style;

  if (getRowStyles) {
    computedStyles = {
      ...computedStyles,
      ...getRowStyles(row),
    };
  }

  return (
    <div className={styles.container} data-table-row-id={row.id}>
      <div
        {...(!row.isSelected && row.getToggleRowExpandedProps())}
        style={computedStyles}
        data-testid="recommendation-table-row"
        className={classNames(
          styles.content,
          row.original.isLoading && styles.skeletonLoader,
          row.isSelected && styles.rowSelected,
          row.isExpanded && styles.rowExpanded,
          rowClassName,
        )}
      >
        {row.cells.map((cell: any) => (
          <div
            {...cell.getCellProps()}
            className={styles.cell}
            testid={cell.column.id}
          >
            {cell.render('Cell')}
          </div>
        ))}

        {(row.original.isLoading ||
          (config.fade_out_minor_recommendations &&
            !doesRecommendationHasChanges(row.original))) && (
          <div className={styles.inactive} />
        )}
      </div>

      {expandedRowBody && (
        <ExpandContent expand={!!row.isExpanded} duration={500}>
          {expandedRowBody(row)}
        </ExpandContent>
      )}
    </div>
  );
};

export default TableRow;
