import { getEnvironmentVariables } from '../environment';
import { obtainTokenApim } from './tokenLogin';
import appApi from './appApi';
import { AxiosResponse } from 'axios';
import { Target } from '../../shared/types/target';
import { getISODateWithoutMilliseconds } from '../../shared/utils/dateHelpers';


const getAPIMToken = async () => {
    const { enableAPIManagement } = getEnvironmentVariables();
    if (enableAPIManagement) {
      const tokenResult = await obtainTokenApim();
      localStorage.setItem('token', tokenResult);
    }
  };


  const getActualsData = async (
    target: Target,
    dateFrom: Date,
    dateTo: Date,
  ): Promise<AxiosResponse<any | undefined>> => {
    await getAPIMToken();
    const dateFromParam = getISODateWithoutMilliseconds(dateFrom);
    const dateToParam = getISODateWithoutMilliseconds(dateTo);

    const result = await appApi.get(`target_actuals/${target.id}`, {
      params: {
        date_from: dateFromParam,
        date_to: dateToParam,
      },
    });
    return result;
  };

  const getPredictionsData = async (
    target: Target,
    dateFrom: Date,
    dateTo: Date,
  ): Promise<AxiosResponse<any | undefined>> => {
    await getAPIMToken();
    const dateFromParam = getISODateWithoutMilliseconds(dateFrom);
    const dateToParam = getISODateWithoutMilliseconds(dateTo);

    const result = await appApi.get(`target_predictions/${target.id}`, {
      params: {
        date_from: dateFromParam,
        date_to: dateToParam,
      },
    });
    return result;
  };

  const getNewActual = async (
    target: Target,
    dateFrom: Date,
    dateTo: Date,
  ): Promise<AxiosResponse<any | undefined>> => {
    await getAPIMToken();
    const dateFromParam = getISODateWithoutMilliseconds(dateFrom);
    const dateToParam = getISODateWithoutMilliseconds(dateTo);
    const result = await appApi.get(`target_actuals_aggregated/${target.id}`,  {
        params: {
          date_from: dateFromParam,
          date_to: dateToParam,
          aggregation: target.aggregation,
        },
      },);
    return result;
  };

  const getTargets= async (): Promise<AxiosResponse<any, any>> => {
    await getAPIMToken();
    const result = await appApi.get('targets');
    return result;
  };


  const getTargetsPredictions = async (
    target: Target,
    dateFrom: Date,
    dateTo: Date,
  ): Promise<AxiosResponse<any | undefined>> => {
    await getAPIMToken();
    const dateFromParam = getISODateWithoutMilliseconds(dateFrom);
    const dateToParam = getISODateWithoutMilliseconds(dateTo);
    const result = await appApi.get(`target_predictions_aggregated/${target.id}`,  {
        params: {
          date_from: dateFromParam,
          date_to: dateToParam,
          aggregation: target.aggregation,
        },
      },);
    return result;
  };

export {
    getActualsData,
    getPredictionsData,
    getTargets,
    getNewActual,
    getTargetsPredictions,
}